import HttpMethod from '@/consts/httpMethod';
import { processRequest, processSuccessResponse, processErrorResponse } from '@/helpers';

export default {
  async createOrder(data) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: '/v1/orders',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data });
      const order = processSuccessResponse(res);

      return order;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
