<template>
  <div id="music">
    <particle-wave :particle-size="9" :number-of-particles="600" :r="255" :g="255" :b="255" />
    <div id="bg-imgs">
      <slick :options="slickOptions" ref="slickRef">
        <div class="slide" v-for="(img, index) in imgs" :key="index">
          <img :src="img.src" />
        </div>
      </slick>

      <div class="overlay" />
      <div class="overlay-2" />
    </div>

    <div class="main-wrapper">
      <div class="container main">
        <p>{{ t('WE_ARE_PART_TIME_MUSICIANS') }}</p>
      </div>

      <div class="main-inner-wrapper" ref="wrapperRef">
        <div class="children">
          <div class="container">
            <div class="row">
              <div class="col col-12 col-md-6 col-lg-4">
                <h1>{{ t('LATEST_RELEASE') }}</h1>
                <p v-if="locale === 'vi'">Các bản thu studio mới nhất của XOI. Bạn có thể nghe &amp; theo dõi band trên <a :href="LINKS.SPOTIFY" target="_blank">Spotify</a>, <a :href="LINKS.BANDCAMP" target="_blank">Bandcamp</a> và các kênh âm nhạc khác.</p>
                <p v-if="locale === 'en'">Our latest studio releases. You can listen &amp; follow us on <a :href="LINKS.SPOTIFY" target="_blank">Spotify</a>, <a :href="LINKS.BANDCAMP" target="_blank">Bandcamp</a> and other online music channels.</p>

                <div class="bandcamp-holder">
                  <iframe src="https://bandcamp.com/EmbeddedPlayer/album=2013853643/size=large/bgcol=282828/linkcol=ac8041/tracklist=false/transparent=true/" seamless><a href="https://xoimusic.bandcamp.com/album/nh-s-ng-ban-ng-y">Ánh Sáng Ban Ngày by XOI</a></iframe>
                </div>
              </div>

              <div class="col col-12 col-md-6 col-lg-8 mv">
                <h1>{{ t('VIDEO') }}</h1>
                <p v-if="locale === 'vi'">Các sản phẩm MV của XOI. Xem thêm &amp; theo dõi band trên <a :href="LINKS.YOUTUBE" target="_blank">Youtube</a>.</p>
                <p v-if="locale === 'en'">Our music video productions. Watch more &amp; follow us on <a :href="LINKS.YOUTUBE" target="_blank">Youtube</a>.</p>

                <h3>{{ mvTitle }}</h3>
                <div class="video-holder">
                  <video controls :poster="mvThumb">
                    <source :src="mv" type="video/mp4">
                  </video>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12">
                <h1>{{ t('ABOUT_XOI') }}</h1>

                <p>{{ t('WE_ARE_PART_TIME_MUSICIANS') }}</p>

                <div class="socials">
                  <p>{{ t('FOLLOW_US_ON') }}:</p>
                  <a :href="LINKS.SPOTIFY" target="_blank"><font-awesome-icon :icon="faSpotify" /></a>
                  <a :href="LINKS.BANDCAMP" target="_blank"><font-awesome-icon :icon="faBandcamp" /></a>
                  <a :href="LINKS.YOUTUBE" target="_blank"><font-awesome-icon :icon="faYoutube" /></a>
                  <a :href="LINKS.INSTAGRAM" target="_blank"><font-awesome-icon :icon="faInstagram" /></a>
                  <a :href="LINKS.FACEBOOK" target="_blank"><font-awesome-icon :icon="faFacebook" /></a>
                </div>

                <p><router-link :to="PATHS.STORE">{{ t('BUY_OUR_PRODUCTS_HERE') }}</router-link></p>
              </div>
            </div>
          </div>
        </div>

        <p class="footer">{{ t('POWERED_BY_XOI') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import $ from 'jquery';
import { TweenMax, Power1 } from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebook, faInstagram, faYoutube, faSpotify, faBandcamp } from '@fortawesome/free-brands-svg-icons';

import { LINKS } from '@/consts';
import PATHS from '@/consts/paths';

import ParticleWave from '@/components/ParticleWave';
import Slick from '@/components/Slick';

import coverImg from '@/assets/imgs/band-min.jpg';

import img000 from '@/assets/imgs/band-imgs/000.jpg';
import img001 from '@/assets/imgs/band-imgs/001.jpg';
import img002 from '@/assets/imgs/band-imgs/002.jpg';
import img003 from '@/assets/imgs/band-imgs/003.jpg';
import img004 from '@/assets/imgs/band-imgs/004.jpg';
import img005 from '@/assets/imgs/band-imgs/005.jpg';
import img006 from '@/assets/imgs/band-imgs/006.jpg';
import img007 from '@/assets/imgs/band-imgs/007.jpg';
import img008 from '@/assets/imgs/band-imgs/008.jpg';

import asbnthumb from '@/assets/imgs/mv-thumbnail-min.jpg';

const slickOptions = {
  arrows: false,
  autoplay: true,
  infinite: true,
  speed: 500,
  fade: true,
  cssEase: 'linear'
};

const imgs = [
  {
    src: img000
  },
  {
    src: img001
  },
  {
    src: img002
  },
  {
    src: img003
  },
  {
    src: img004
  },
  {
    src: img005
  },
  {
    src: img006
  },
  {
    src: img007
  },
  {
    src: img008
  },
];

const mv = 'https://assets.xoi.vn/xoi-anh-sang-ban-ngay.mp4';
const mvThumb = asbnthumb;
const mvTitle = 'XOI - Ánh Sáng Ban Ngày (Official Music Video)';

export default {
  name: 'Music',
  components: {
    ParticleWave,
    Slick,
    FontAwesomeIcon
  },
  setup() {
    const { t, locale } = useI18n();

    const title = t('MUSIC');
    const description = t('WE_ARE_PART_TIME_MUSICIANS');

    const { meta } = useMeta({
      title,
      description,
      og: {
        title: `${title} | XOI`,
        description,
        image: [coverImg]
      },
    });

    watch(locale, () => {
      meta.title = t('MUSIC');
    });

    const wrapperRef = ref(null);
    const slickRef = ref(null);
    const freeze = ref(false);

    const scrollChildren = () => {
      if (!wrapperRef.value || !wrapperRef.value) return;
      if (!slickRef.value || !slickRef.value) return;

      const top = $(window).scrollTop();

      if (top > window.innerHeight) {
        slickRef.value.pause();
        freeze.value = true;
      } else {
        slickRef.value.play();
        freeze.value = false;
      }

      TweenMax.to(wrapperRef.value, .8, {
        ease: Power1.easeOut,
        y: -top
      });
    };

    const offsetScroll = () => {
      const offset = 110;

      TweenMax.set(wrapperRef.value, {
        y: 0
      });

      TweenMax.set('body', {
        height: wrapperRef.value.scrollHeight + offset
      });
    };

    onMounted(() => {
      $(window).on('scroll', scrollChildren);
      $(window).on('resize', offsetScroll);
      offsetScroll();
    });

    onUnmounted(() => {
      $(window).off('scroll', scrollChildren);
      $(window).off('resize', offsetScroll);
      TweenMax.set('body', {
        height: null
      });
    });

    return {
      t,
      locale,
      slickOptions,
      imgs,
      wrapperRef,
      slickRef,
      mv,
      mvThumb,
      mvTitle,
      faFacebook,
      faInstagram,
      faYoutube,
      faSpotify,
      faBandcamp,
      LINKS,
      PATHS
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#music {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: $black;

  a {
    font-weight: bold;
  }

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: 100vh;

    canvas {
      background-color: $black;
    }
  }

  #bg-imgs {
    height: 100vh;
    overflow: hidden;
    opacity: .5;
    position: relative;

    .slider {
      height: 100%;

      .item {
        height: 100%;
      }
    }

    .slick-list {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }

    .slick-slide {
      height: auto;
    }

    .slide {
      height: 100%;
      filter: saturate(0%);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    >.overlay {
      background-color: $orangeYellow;
      mix-blend-mode: color-burn;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      position: absolute;
    }

    .overlay-2 {
      background-color: $black;
      mix-blend-mode: saturation;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      position: absolute;
    }
  }

  .main-wrapper {
    position: absolute;
    top: 90px;
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 110px);
    overflow: hidden;

    .main {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      color: $white;
      text-align: center;

      p {
        font-size: 24px;
      }
    }

    .main-inner-wrapper {
      padding-top: calc(100vh - 40px);

      .children {
        position: relative;
        z-index: 9;
        padding: 80px 0;
        text-align: left;
        background-color: $white;

        .container {
          max-width: 960px;
        }

        .col {
          margin-bottom: 50px;
        }

        .bandcamp-holder {
          width: 100%;
          padding-bottom: 120%;
          position: relative;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            border: 0;
          }
        }

        .video-holder {
          width: 100%;
          padding-bottom: 50%;
          position: relative;

          video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            border: 0;

            &:active {
              border: none;
            }
          }
        }

        .mv {
          h3 {
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 30px;
            font-size: 18px;
          }
        }

        .socials {
          svg {
            margin-right: 10px;
            font-size: 24px;
            margin-bottom: 10px;
          }
        }
      }

      .footer {
        text-align: center;
        color: $white;
        font-size: 12px;
        padding: 20px 0;
      }
    }
  }
}
</style>
