<template>
  <div id="home">
    <particle-circle :speed="90 * 1000" :sub-speed="10000" :number-of-particles="600" :radius="radius" />
    <div class="logo-holder">
      <logo />
    </div>

    <div class="nav-menu" v-if="isDesktop">
      <div v-for="item in menuItems" :key="item.text" class="menu-item" :class="{ [item.class]: true }">
        <router-link :to="item.path || '/'">
          <div class="wrapper">
            <div class="block">
              <primary-button :text="t(item.text)" />
            </div>
            <div class="line">
              <div class="circle" />
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

import PATHS from '@/consts/paths';

import Logo from '@/components/Logo';
import ParticleCircle from '@/components/ParticleCircle';
import PrimaryButton from '@/components/PrimaryButton';

const menuItems = [
  {
    text: 'ABOUT_XOI',
    class: 'about right',
    path: PATHS.ABOUT
  },
  {
    text: 'SOFTWARE_DEVELOPMENT',
    class: 'dev left',
    path: PATHS.UNDER_CONSTRUCTION
  },
  {
    text: 'GRAPHICS',
    class: 'graphics left',
    path: PATHS.UNDER_CONSTRUCTION
  },
  {
    text: 'MUSIC',
    class: 'music left',
    path: PATHS.MUSIC
  },
  {
    text: 'STORIES',
    class: 'stories left',
    path: PATHS.STORIES
  },
  {
    text: 'SHOP',
    class: 'store right',
    path: PATHS.SHOP
  },
  {
    text: 'CONTACTS',
    class: 'contacts right',
    path: PATHS.CONTACTS
  },
];

export default {
  name: 'Home',
  components: {
    ParticleCircle,
    Logo,
    PrimaryButton
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const title = t('CORE_REACTOR');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | XOI`
      },
    });

    watch(locale, () => {
      meta.title = t('CORE_REACTOR');
    });

    const radius = ref(600);
    const isDesktop = computed(() => store.state.app.isDesktop);

    const watchResize = () => {
      const w = window.innerWidth;

      if (w >= 1440) {
        radius.value = 500;
      } else if (w >= 920) {
        radius.value = 400;
      } else if (w >= 414) {
        radius.value = 220;
      } else {
        radius.value = 220;
      }
    };

    onMounted(() => {
      window.addEventListener('resize', watchResize);

      watchResize();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', watchResize);
    });

    return {
      t,
      menuItems,
      radius,
      isDesktop
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#home {
  width: 100%;
  height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;

  #particle-circle {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});
    z-index: -1;
  }

  .logo-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 300px;
    height: 300px;
    user-select: none;
    color: $black;

    .logo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .nav-menu {
    width: 0px;
    height: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: border-box;

    .menu-item {
      display: inline-block;
      transform-origin: center;
      transform: translateY(-50%);
      position: absolute;
      top: 0;
      left: 0;

      .wrapper {
          transform-origin: center;
          position: relative;

          &:hover {
          .line {
            border-color: $black;

            .circle {
              width: 40px;
              height: 40px;
              background-color: $black;
              bottom: -20px;
              cursor: pointer;
            }
          }

          .block {
            .primary-button {
              color: $white;

              &::before {
                transform: scale(1.2);
                transform-origin: center;
                opacity: 0;
              }

              &::after {
                transform: scale(1);
                transform-origin: center;
                opacity: 1;
              }
            }
          }
        }
      }

      .line {
        width: 0;
        height: 150px;
        border-left: 1px solid $alto;
        position: absolute;
        left: 50%;
        top: 30px;
        transition: all .2s ease-in-out;
        z-index: -1;
        transform-origin: top;

        .circle {
          box-sizing: border-box;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          position: absolute;
          bottom: -8px;
          left: calc(50% + 0.5px);
          transform: translateX(-50%);
          background-color: $alto;
          opacity: .9;
          transition: all .2s ease-in-out;
        }
      }

      .block {
        box-sizing: border-box;
        height: 60px;
        cursor: pointer;

        .primary-button {
          text-transform: uppercase;
          vertical-align: middle;
          padding-left: 60px;
          padding-right: 60px;
          height: 60px;
          white-space: nowrap;
          font-size: 20px;
        }
      }

      &.left {
        .wrapper {
          transform: translateX(-680px);

          @media (max-width: 1439px) {
            transform: translateX(-575px);
          }
        }
      }

      &.right {
        .wrapper {
          transform: translateX(420px) translateY(170px);

          @media (max-width: 1439px) {
            transform: translateX(390px)  translateY(120px);
          }
        }
      }

      &.dev {
        .wrapper {
          top: -200px;

          .line {
            transform: rotate(-90deg);
            height: 290px;

            @media (max-width: 1439px) {
              height: 265px;
            }
          }
        }
      }

      &.music {
        .wrapper {
          top: -120px;

          .line {
            transform: rotate(-90deg);
            height: 290px;

            @media (max-width: 1439px) {
              height: 260px;
            }
          }
        }
      }

      &.graphics {
        .wrapper {
          top: -40px;

          .line {
            transform: rotate(-90deg);
            height: 265px;

            @media (max-width: 1439px) {
              height: 227px;
            }
          }
        }
      }

      &.stories {
        .wrapper {
          top: 40px;

          .line {
            transform: rotate(-90deg);
            height: 270px;

            @media (max-width: 1439px) {
              height: 232px;
            }
          }
        }
      }

      &.store {
        .wrapper {
          top: -80px;

          .line {
            transform: rotate(90deg);
            height: 178px;

            @media (max-width: 1439px) {
              height: 197px;
            }
          }
        }
      }

      &.about {
        .wrapper {
          .line {
            transform: rotate(90deg);
            height: 220px;

            @media (max-width: 1439px) {
              height: 235px;
            }
          }
        }
      }

      &.contacts {
        .wrapper {
          top: 80px;

          .line {
            transform: rotate(90deg);
            height: 291px;
          }
        }
      }
    }
  }
}
</style>
