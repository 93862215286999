import HttpMethod from '@/consts/httpMethod';
import { processRequest, processSuccessResponse, processErrorResponse } from '@/helpers';

export default {
  async getMeta(route) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: `/v1/metas${route}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, {}, false);
      const data = processSuccessResponse(res);

      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
