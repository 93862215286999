import { TweenMax } from 'gsap';
import axios from 'axios';

import upsideDownTextDictionary from '@/assets/json/upsideDownTextDictionary.json';

import * as errors from '@/errors';

export const scrollToTop = () => {
  if (process.browser) {
    TweenMax.to(window, .3, {
      scrollTo: 0
    });
  }
};

export const rangeRandom = (low, high) => (Math.random() * (high - low) + low);

export const normalDistribute = (mean, dev) => {
  let r;
  let a;
  let n;

  do {
    r = (a = 2 * Math.random() - 1) * a + (n = 2 * Math.random() - 1) * n;
  } while (r >= 1);

  const e = a * Math.sqrt((-2 * Math.log(r)) / r);

  return dev * e + mean;
};

export const processRequest = (ep, config, showLoader = true) => {
  const { method, url, baseURL } = ep;

  return axios({
    headers: { 'Content-Type': 'application/json' },
    baseURL,
    method,
    url,
    ...config,
    showLoader
  });
};

export const processSuccessResponse = (res = {}) => {
  const data = res?.data?.data;
  const { InvalidResponse } = errors;

  if (!data) {
    throw new InvalidResponse();
  }

  return data;
};

export const processErrorResponse = (res = {}) => {
  const error = res?.data?.error;
  const { InvalidResponse, UnknownError } = errors;

  if (!error) {
    throw new InvalidResponse();
  }

  const { code, message } = error;

  Object.keys(errors).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(errors, key)) {
      if (code === errors[key].code) {
        throw new errors[key](message);
      }
    }
  });

  throw new UnknownError(message || 'Unknown Error');
};

export const applyParams = (...args) => {
  let route = args[0] || '';

  let i = 1;
  while (i < args.length) {
    route = route.replace(/:[a-zA-Z-_+*().]+\??/, args[i]);
    i += 1;
  }

  return route;
};

export const addCommas = (str_, comma_) => {
  if (!str_) return 0;
  let str = str_.toString();

  while (str[0] === '0') str = str.substring(1, str.length);

  const comma = comma_ || ',';

  let s = str;
  let tail = '';

  const dot = str.indexOf(comma === ',' ? '.' : ',');

  if ((dot >= 0) && (dot !== str.length - 1)) {
    tail = str.substring(dot + 1, str.length);

    if (comma === ',') {
      tail = `.${tail}`;
    } else {
      tail = `,${tail}`;
    }

    s = str.substring(0, dot);
  }

  let signed = false;
  if (s[0] === '-') {
    signed = true;
  }

  s = s.replace(/\D/g, '');

  if (s.length < 4) return `${s}${tail}`;

  let temp = '';
  let n = 0;

  for (let i = s.length - 1; i >= 0; i -= 1) {
    temp = `${s[i]}${temp}`;
    n += 1;
    if ((n % 3 === 0) && (i > 0)) temp = `${comma}${temp}`;
  }
  return `${signed ? '-' : ''}${temp}${tail}`;
};

export const removeCommas = (str_, comma_) => {
  if (!str_) return 0;
  let str = str_.toString();

  const comma = comma_ || ',';

  str = str.replaceAll(comma, '');
  if (comma === '.') {
    str = str.replace(',', '.');
  }

  return parseFloat(str, 10);
};

/* eslint-disabled */
export const upsideDownText = (str) => {
  let result = '';
  let i = str.length - 1;
  while (i >= 0) {
    const c = str.charAt(i);
    result += upsideDownTextDictionary[c] || c;
    i -= 1;
  }

  return result;
};
