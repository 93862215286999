<template>
  <div id="particle-circle">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

import { rangeRandom, normalDistribute } from '@/helpers';

export default {
  name: 'ParticleCircle',
  props: {
    numberOfParticles: {
      type: Number,
      default: 1000
    },
    particleSize: {
      type: Number,
      default: 7
    },
    speed: {
      type: Number,
      default: 60000
    },
    subSpeed: {
      type: Number,
      default: 5000
    },
    mouseRadius: {
      type: Number,
      default: 100
    },
    r: {
      type: Number,
      default: 14
    },
    g: {
      type: Number,
      default: 16
    },
    b: {
      type: Number,
      default: 15
    },
    radius: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const canvas = ref(null);
    const mouseX = ref(0);
    const mouseY = ref(0);
    const particles = ref([]);

    const createParticle = () => ({
      diameter: Math.max(0, normalDistribute(props.particleSize, props.particleSize / 2)),
      duration: normalDistribute(props.speed, props.speed * 0.1),
      subDuration: normalDistribute(props.subSpeed, props.subSpeed * 0.1),
      amplitude: normalDistribute(5, rangeRandom(2, 5)),
      offsetY: normalDistribute(0, rangeRandom(7, 10)),
      startTime: performance.now() - rangeRandom(0, props.speed),
      colour: `rgba(${props.r}, ${props.g}, ${props.b}, ${rangeRandom(0, 1)})`
    });

    const drawParticle = (particle, time) => {
      if (!canvas.value) return;

      const progress = ((time - particle.startTime) % particle.duration) / particle.duration;
      const subProgress = ((time - particle.startTime) % particle.subDuration) / particle.subDuration;

      const center = {
        x: canvas.value.width / 2,
        y: canvas.value.height / 2
      };

      let baseRadius = (props.radius * window.devicePixelRatio) / 1.5;
      if (!baseRadius) {
        baseRadius = (canvas.value.width < canvas.value.height) ? (canvas.value.width / 19) * 7 : canvas.value.height / 3;
      }
      let radius = (Math.sin(subProgress * 2 * Math.PI) * particle.offsetY) * particle.amplitude + baseRadius;

      let x = center.x + radius * Math.sin(progress * 2 * Math.PI);
      let y = center.y + radius * Math.cos(progress * 2 * Math.PI);

      if (
        (x >= mouseX.value - props.mouseRadius)
        && (x <= mouseX.value + props.mouseRadius)
        && (y >= mouseY.value - props.mouseRadius)
        && (y <= mouseY.value + props.mouseRadius)
      ) {
        radius = (Math.sin(subProgress * 2 * Math.PI) * particle.offsetY) * 20 + baseRadius;
        x = center.x + radius * Math.sin(progress * 2 * Math.PI);
        y = center.y + radius * Math.cos(progress * 2 * Math.PI);
      }

      const diameter = (particle.diameter * window.devicePixelRatio) / 1.5;

      const ctx = canvas.value.getContext('2d');

      ctx.fillStyle = particle.colour;
      ctx.beginPath();
      ctx.ellipse(
        x,
        y,
        diameter,
        diameter,
        0,
        0,
        2 * Math.PI
      );
      ctx.fill();
    };

    const draw = (time) => {
      if (!canvas.value) return;

      const ctx = canvas.value.getContext('2d');
      ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);

      particles.value.forEach((particle) => {
        drawParticle(particle, time);
      });

      requestAnimationFrame((t) => draw(t));
    };

    const startAnimation = () => {
      const parts = [];
      for (let i = 0; i < props.numberOfParticles; i += 1) {
        parts.push(createParticle());
      }

      particles.value = parts;

      requestAnimationFrame((time) => draw(time));
    };

    const initCanvas = () => {
      if (!canvas.value) return;

      canvas.value.width = canvas.value.offsetWidth * window.devicePixelRatio;
      canvas.value.height = canvas.value.offsetHeight * window.devicePixelRatio;
    };

    const watchMousePos = (e) => {
      const rect = canvas.value.getBoundingClientRect();
      mouseX.value = e.clientX * window.devicePixelRatio - rect.left;
      mouseY.value = e.clientY * window.devicePixelRatio - rect.top;
    };

    onMounted(() => {
      window.addEventListener('resize', initCanvas);
      window.addEventListener('mousemove', watchMousePos);

      setTimeout(() => {
        initCanvas();
        startAnimation();
      }, 0);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', initCanvas);
      window.removeEventListener('mousemove', watchMousePos);
    });

    return {
      canvas
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

#particle-circle {
  width: 100%;
  height: 100%;

  canvas {
    width: 100%;
    height: 100%;
    background-color: $white;
    vertical-align: middle;
  }
}
</style>
