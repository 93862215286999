export default {
  HOME: '/',
  MUSIC: '/music',
  ABOUT: '/about-xoi',
  STORE: '/store',
  SHOP: '/shop',
  PRODUCT_INFO: '/shop/:slug',
  CART: '/cart',
  CHECKOUT: '/checkout',
  CONTACTS: '/contacts',
  UNDER_CONSTRUCTION: '/under-construction',
  STORIES: '/stories',
  STORY_PIECE: '/stories/:slug(.+)',

  UPSIDE_DOWN_TEXT: '/upside-down-text-generator',

  SERVICE_FEE: '/service-fee'
};
