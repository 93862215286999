<template>
  <div id="contacts">
    <particle-wave :particle-size="9" :number-of-particles="600" />
    <div class="content">
      <div class="wrapper">
        <h1>{{ t('CONTACTS') }}</h1>

        <div>
          <ul class="socials">
            <li v-for="link in links" :key="link.text">
              <a :href="link.url || ''" :target="link.target || '_self'">
                <font-awesome-icon :icon="link.icon" />
                <span>{{ link.text }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPaperPlane, faMobile } from '@fortawesome/pro-duotone-svg-icons';
import { faFacebook, faSpotify, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

import ParticleWave from '@/components/ParticleWave';

const links = [
  {
    icon: faPaperPlane,
    text: 'info@xoi.vn',
    url: 'mailto:info@xoi.vn',
    target: '_blank'
  },
  {
    icon: faMobile,
    text: '+84 2822 000 155',
    url: 'tel:+842822000155'
  },
  {
    icon: faSpotify,
    text: 'sptfy.com/xoi',
    url: 'https://open.spotify.com/artist/15HLcx4BDvQLmcu893h3C3?si=ZvLdJZfDTXmYNP_QJ09Liw',
    target: '_blank'
  },
  {
    icon: faYoutube,
    text: '@xoi.official',
    url: 'https://www.youtube.com/@xoi.official',
    target: '_blank'
  },
  {
    icon: faFacebook,
    text: 'xoiwashere',
    url: 'https://www.facebook.com/xoiwashere',
    target: '_blank'
  },
  {
    icon: faInstagram,
    text: 'xoi.official',
    url: 'https://www.instagram.com/xoi.official/',
    target: '_blank'
  }
];

export default {
  name: 'About',
  components: {
    ParticleWave,
    FontAwesomeIcon
  },
  setup() {
    const { t, locale } = useI18n();

    const title = t('CONTACTS');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | XOI`
      },
    });

    watch(locale, () => {
      meta.title = t('CONTACTS');
    });

    return {
      t,
      links
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#contacts {
  width: 100%;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});
    z-index: -1;
  }

  .socials {
    padding: 0;
    margin: 0;
    margin-top: 50px;
    list-style: none;

    li {
      margin: 20px 0;

      svg {
        margin-right: 10px;
        font-size: 20px;
        width: 30px;
      }

      svg, span {
        vertical-align: middle;
      }
    }
  }
}
</style>
