<template>
  <div id="under-construction">
    <particle-wave :particle-size="9" :number-of-particles="600" :r="255" :g="255" :b="255" />
    <div class="content">
      <div class="wrapper">
        <h1>{{ t('UNDER_CONSTRUCTION') }}</h1>

        <div>
          {{ t('THIS_PART_OF_THE_WEBSITE_IS_UNDER_CONSTRUCTION') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';

import ParticleWave from '@/components/ParticleWave';

export default {
  name: 'UnderConstruction',
  components: {
    ParticleWave
  },
  setup() {
    const { t, locale } = useI18n();

    const title = t('UNDER_CONSTRUCTION');

    const { meta } = useMeta({
      title,
      og: {
        title
      },
    });

    watch(locale, () => {
      meta.title = t('UNDER_CONSTRUCTION');
    });

    return {
      t,
      locale
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#under-construction {
  width: 100%;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;
  background-color: $black;

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});

    canvas {
      background-color: $black;
    }
  }

  .content {
    .wrapper {
      color: $white;
      background-color: rgba(14, 16, 15, .97);
    }
  }
}
</style>
