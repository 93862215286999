export const INIT_STATE = {
  filters: {
    page: 1,
    pageSize: 20
  },
  items: [],
  total: 0,
  fetching: false
};

const mutations = {
  applyFilters(state, filters) {
    if (state.total > 0) {
      const totalPages = Math.ceil(state.total / state.filters.pageSize);
      if (filters.page > totalPages) {
        return;
      }
    }

    state.filters = {
      ...state.filters,
      ...filters
    };
  },

  setItems(state, { items, total }) {
    state.items = [...items];
    state.total = total;
  },

  setFetching(state, { val }) {
    state.fetching = !!val;
  },

  setAppend(state, { val }) {
    state.append = !!val;
  },

  reset(state) {
    Object.keys(INIT_STATE).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(INIT_STATE, key)) {
        state[key] = INIT_STATE[key];
      }
    });
  }
};

export default mutations;
