<template>
  <div id="about">
    <particle-wave :particle-size="9" :number-of-particles="600" />
    <div class="content">
      <div class="wrapper">
        <h1>{{ t('ABOUT_XOI') }}</h1>

        <div v-show="(locale === 'en')">
          <p>XOI is a creativity outlet created in 2013, in Ho Chi Minh city, Vietnam.</p>
          <p>You might get confused of who we are and what we do. The truth is, half of the time, I don’t even know.</p>
          <p>Hi, my name is <a href="https://www.cuongxoi.com" title="Cuong XOI" target="_blank">Cuong</a>, formally, the founder, really, the only one who cares.</p>
          <p>At first, I registered the legal entity in order to intern for myself and get the compulsory credits required by my university's curriculum. Having had worked in web development as a freelancer for a few years, I believed that an internship for a company, no matter how large, would only slow me down. After three months, I signed my own intern certificate, rated myself “technically good but lacks discipline”.</p>
          <p>On the artistic side, in the same year, my rock band since high school was splitting up. We sucked, but because I didn’t want to waste the knowledge and skills acquired during the six years of musical activities, I started a new journey, moved from drummer position, I picked up the guitar, wrote my own songs, started having vocal lessons and initiated a new music project.</p>
          <p>In 2015, XOI started to have more people who were crazy enough to join in.</p>
          <p>In 2016, after 3 times of moving the 8-track studio around, due to noise complaints, XOI finally settled down in a small room in Nha Be, a suburb area just outside of Ho Chi Minh city. Later, in 2020, I was able to built another home studio in a slightly larger room in District 7, determined to work toward becoming our own record label.</p>
          <p>Always more focused on lyrics than melodies when writing the songs, in 2017, as words were overflowing, I realised that XOI needs another medium to contain the thirst for creativity and started my venture into literature, with help, writing short stories and novels.</p>
          <p>In the meantime, I kept working as a software engineer. In 2020, after years of experience with start-ups all around HCMC, XOI started working on its own products of web and mobile applications.</p>
          <p>And that’s basically it. That’s XOI, trying to create out of anything that it can.</p>
          <p>I hope to keep learning in the years to come and to be able to meet and work with more crazy people who have the same enthusiasm for new values.</p>
        </div>

        <div v-show="(locale === 'vi')">
          <p>XOI là nỗ lực sáng tạo được lập ra từ năm 2013, tại thành phố Hồ Chí Minh, Việt Nam.</p>
          <p>Có thể bạn sẽ thấy khó hiểu về việc chúng tôi là ai và chúng tôi làm gì. Sự thật là, đôi khi tôi cũng chẳng biết nữa.</p>
          <p>Xao chìn, tên tôi là <a href="https://www.cuongxoi.com" title="Cuong XOI" target="_blank">Cường</a>, trên danh nghĩa, tôi là người sáng lập ra XOI, trên thực tế, chẳng có ai quan tâm, ngoài tôi.</p>
          <p>Ban đầu, tôi đăng ký pháp nhân này để lấy chỉ môn thực tập trong chương trình của trường đại học. Khi đó, do đã làm việc freelance trong lĩnh vực xây dựng web được vài năm, tôi cho rằng một khóa thực tập tại một công ty, dù quy mô lớn hay nhỏ, cũng không giúp được gì thêm nhiều cho mình. Sau ba tháng, tôi tự ký giấy chứng nhận thực tập và tự ghi đánh giá <i>“kĩ năng tốt nhưng thiếu kỉ luật”</i>.</p>
          <p>Về mảng nghệ thuật, cũng trong năm đó, band nhạc rock với các bạn học cấp ba tan rã. Không muốn bỏ phí quãng đường sáu năm hoạt động âm nhạc, tôi bắt đầu hành trình mới, chuyển từ vị trí đánh trống sang tập guitar, tự viết các sáng tác, bắt đầu học thanh nhạc, và tạo ra một dự án âm nhạc mới.</p>
          <p>Từ 2015, XOI bắt đầu có sự tham gia của những thành viên khác.</p>
          <p>Năm 2016, sau ba lần di chuyển phòng thu 8-track khắp nơi do tiếng ồn gây ảnh hưởng, XOI ổn định được ví trí phòng thu tại một căn phòng nhỏ ở Nhà Bè, ngoại ô thành phố Hồ Chí Minh. Về sau, năm 2020, XOI dựng được một phòng thu nhỏ nữa ở Quận 7, cố gắng phát triển để có thể thành hãng thu cho riêng mình.</p>
          <p>Luôn chú trọng vào lời hát nhiều hơn là giai điệu trong các sáng tác, năm 2017, tôi nhận ra rằng XOI cần thêm một hình thức nghệ thuật nữa để chứa đựng hết những ý tưởng đang tràn ra trong đầu mình, tối dấn thân vào văn học, hợp tác và bắt đầu viết các truyện ngắn và tiểu thuyết.</p>
          <p>Trong thời gian đó, tôi vẫn tiếp tục làm việc phát triển phần mềm. Năm 2020, sau nhiều năm kinh nghiệm làm việc với các dự án khởi nghiệp công nghệ khắp Sài Gòn, XOI bắt đầu phát triển các ứng dựng web &amp; mobile của riêng mình.</p>
          <p>Và về cơ bản, đó là XOI, những nỗ lực sáng tạo từ bất cứ thứ gì có thể.</p>
          <p>Hy vọng trong thời gian tới XOI sẽ tiếp tục được học hỏi, được gặp gỡ và hợp tác với những người có chung nhiệt huyết cho những giá trị mới.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';

import ParticleWave from '@/components/ParticleWave';

export default {
  name: 'About',
  components: {
    ParticleWave
  },
  setup() {
    const { t, locale } = useI18n();

    const title = t('ABOUT_XOI');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | XOI`
      },
    });

    watch(locale, () => {
      meta.title = t('ABOUT_XOI');
    });

    return {
      t,
      locale
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#about {
  width: 100%;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});
    z-index: -1;
  }

  h1 {
    margin-bottom: 30px;
  }
}
</style>
