import userService from '@/services/user';

import { SET_TOKEN, SET_REFRESH_TOKEN, SET_PROFILE, CLEAR } from './types';

const actions = {
  async login({ commit }, { username, password }) {
    const { token, refreshToken } = await userService.login(username, password);
    commit(SET_TOKEN, token);
    commit(SET_REFRESH_TOKEN, refreshToken);

    const { firstName, lastName, picture } = await userService.getProfile();
    commit(SET_PROFILE, { firstName, lastName, picture, username });
  },

  async guestLogin({ commit }) {
    const { token, refreshToken } = await userService.login();

    commit(SET_TOKEN, token);
    commit(SET_REFRESH_TOKEN, refreshToken);
  },

  async refreshToken({ commit, state }) {
    commit(SET_TOKEN, '');

    const { refreshToken } = state;
    const { token } = await userService.refreshToken(refreshToken);

    commit(SET_TOKEN, token);
  },

  logout({ commit }) {
    commit(CLEAR);
  }
};

export default actions;
