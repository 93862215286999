<template>
  <div id="cart">
    <particle-wave :particle-size="9" :number-of-particles="600" />
    <div class="content">
      <div class="wrapper">
        <h1>{{ t('CART') }}</h1>
        <p v-if="itemsCount > 1">{{ t('THERE_ARE_X_ITEMS_IN_YOUR_CART', { x: itemsCount }) }}</p>
        <p v-if="itemsCount === 1">{{ t('THERE_IS_ONE_ITEM_IN_YOUR_CART') }}</p>
        <p v-if="itemsCount < 1">{{ t('YOUR_CART_IS_EMPTY') }}</p>

        <div class="cart-items">
          <div v-for="item in items" :key="item.product.id" class="item">
            <div class="info">
              <div class="image">
                <img :src="item.product.image.url" :alt="item.product.name" />
              </div>
              <div class="text">
                <h3>{{ item.product.name }}</h3>
                <p>{{ item.product.description }}</p>
              </div>
            </div>

            <div class="sum">
              <quantity-input :min="0" :max="10" v-model:value="item.quantity" @update:value="updateItem(item)" />
              <div class="amount">{{ addCommas(item.product.price * item.quantity) }} ₫</div>
            </div>
          </div>
        </div>

        <div class="total" v-if="itemsCount > 0">
          <div class="text">{{ t('TOTAL') }}</div>
          <div class="amount">{{ addCommas(totalAmount) }} ₫</div>
        </div>

        <div class="controls">
          <router-link :to="PATHS.STORE">
            <primary-button inverse :text="t('CONTINUE_SHOPPING')" />
          </router-link>
          <router-link :to="PATHS.CHECKOUT">
            <primary-button v-if="itemsCount > 0" colored inverse :text="t('CHECKOUT')" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import { useStore } from 'vuex';

import { addCommas } from '@/helpers';
import PATHS from '@/consts/paths';

import ParticleWave from '@/components/ParticleWave';
import QuantityInput from '@/components/QuantityInput';
import PrimaryButton from '@/components/PrimaryButton';

export default {
  name: 'Cart',
  components: {
    ParticleWave,
    QuantityInput,
    PrimaryButton
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const title = t('CART');

    const { meta } = useMeta({
      title,
      og: {
        title
      },
    });

    const items = computed(() => store.state.cart.items);
    const itemsCount = computed(() => store.getters['cart/itemsCount']);
    const totalAmount = computed(() => store.getters['cart/totalAmount']);

    const updateItem = (item) => {
      store.dispatch('cart/updateItem', item);
    };

    watch(locale, () => {
      meta.title = t('CART');
    });

    return {
      t,
      locale,
      items,
      addCommas,
      updateItem,
      itemsCount,
      totalAmount,
      PATHS
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#cart {
  width: 100%;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});
    z-index: -1;
  }

  .cart-items {
    margin-top: 80px;

    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .info {
        width: calc(100% - #{$quantityInputWidth});
        display: flex;
        justify-content: space-between;

        .image {
          width: 90px;
          height: 90px;
          position: relative;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;;
            object-fit: cover;
          }
        }

        .text {
          width: calc(100% - 90px);
          padding-right: 30px;
          padding-left: 10px;

          h3 {
            font-size: 24px;
            font-family: 'Trump Gothic Pro';
          }
        }
      }

      .sum {
        margin-top: 10px;
        width: $quantityInputWidth;

        .amount {
          font-weight: bold;
          font-size: 12px;
          margin-top: 5px;
        }
      }

      @media (max-width: 575px) {
        display: block;
        position: relative;

        .info {
          display: inline-block;
          width: 100%;

          .text {
            padding: 0;
            padding-top: 10px;
            width: 100%;
          }
        }

        .sum {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

  .total {
    margin-top: 15px;
    font-weight: bold;
    text-align: right;
  }

  .controls {
    margin-top: 50px;
    text-align: right;

    .primary-button {
      margin-left: 15px;
      text-transform: uppercase;
    }
  }
}
</style>
