import { createRouter, createWebHistory } from 'vue-router';

import { scrollToTop } from '@/helpers';
import PATHS from '@/consts/paths';

import Home from './views/Home';
import About from './views/About';
import Music from './views/Music';
import Contacts from './views/Contacts';
import UnderConstruction from './views/UnderConstruction';

import Stories from './views/Stories';
import StoryPiece from './views/StoryPiece';

import Products from './views/Products';
import ProductInfo from './views/ProductInfo';
import Cart from './views/Cart';
import Checkout from './views/Checkout';

import UpsideDownText from './views/UpsideDownText';

import ServiceFee from './views/ServiceFee';

const routes = [
  { path: '/:catchAll(.*)', redirect: PATHS.HOME },
  {
    path: PATHS.HOME,
    component: Home,
    name: 'home'
  },
  {
    path: PATHS.MUSIC,
    component: Music,
    name: 'music',
    meta: {
      dark: true
    }
  },
  {
    path: PATHS.ABOUT,
    component: About,
    name: 'about'
  },
  {
    path: PATHS.SHOP,
    component: Products,
    name: 'products',
    alias: PATHS.STORE
  },
  {
    path: PATHS.PRODUCT_INFO,
    component: ProductInfo,
    name: 'product-info'
  },
  {
    path: PATHS.CART,
    component: Cart,
    name: 'cart',
  },
  {
    path: PATHS.CHECKOUT,
    component: Checkout,
    name: 'checkout',
  },
  {
    path: PATHS.CONTACTS,
    component: Contacts,
    name: 'contacts'
  },
  {
    path: PATHS.UNDER_CONSTRUCTION,
    component: UnderConstruction,
    name: 'under-construction',
    meta: {
      dark: true
    }
  },
  {
    path: PATHS.STORIES,
    component: Stories,
    name: 'stories-list',
    meta: {
      dark: true
    }
  },
  {
    path: PATHS.STORY_PIECE,
    component: StoryPiece,
    name: 'story-piece',
    meta: {
      dark: true
    }
  },

  {
    path: PATHS.UPSIDE_DOWN_TEXT,
    component: UpsideDownText,
    name: 'upside-down-text'
  },

  {
    path: PATHS.SERVICE_FEE,
    component: ServiceFee,
    name: 'service-fee'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name !== from.name) {
      scrollToTop();
    }

    return savedPosition;
  }
});

export default router;
