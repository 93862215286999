<template>
  <button
    class="primary-button button"
    :class="{
      disabled,
      inverse,
      colored
    }"
    :type="type"
  >
    <span v-if="!!icon" class="icon">
      <font-awesome-icon :icon="icon" />
    </span>
    <span class="text">{{ text }}</span>
  </button>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'PrimaryButton',
  components: {
    FontAwesomeIcon
  },
  props: {
    text: String,
    type: {
      type: String,
      default: 'button'
    },
    disabled: Boolean,
    inverse: Boolean,
    colored: Boolean,
    icon: Object
  },
  setup() {
    return {
      onclick,
      onmouseover,
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';

.primary-button {
  height: 48px;
  border: 0;
  border-radius: 2px;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 30px;
  padding-bottom: 5px;
  color: $black;
  font-size: 18px;
  font-weight: 500;
  transition: all .2s ease-in-out;
  cursor: pointer;
  position: relative;
  z-index: 1;
  user-select: none;
  text-transform: capitalize;
  font-family: "Trump Gothic Pro", Nunito;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $ghostWhite;
    border: 1px solid $alto;
    z-index: -1;
    box-sizing: border-box;
    opacity: 1;
    transition: all .2s ease-in-out;
    border-radius: 2px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: $black;
    z-index: -1;
    opacity: 0;
    transform: scale(0.7);
    transition: all .2s ease-in-out;
    border-radius: 2px;
  }

  &.disabled {
    color: $grey;
    border-color: $grey;
    pointer-events: none;
    background-color: $alto;
  }

  &:hover {
    color: $white;

    &::before {
      transform: scale(1.2);
      transform-origin: center;
      opacity: 0;
    }

    &::after {
      transform: scale(1);
      transform-origin: center;
      opacity: 1;
    }
  }

  &.inverse {
    color: $white;

    &::before {
      background-color: $black;
      border: none;
    }

    &::after {
      background-color: $ghostWhite;
      border: 1px solid $alto;
    }

    &:hover {
      color: $black;
    }
  }

  &.colored {
    color: $black;

    &::before {
      background-color: $white;
      border: none;
    }

    &::after {
      background-color: $orangeYellow;
      border: 1px solid $black;
    }

    &:hover {
      color: $black;
    }

    &.inverse {
      color: $black;

      &::before {
        background-color: $orangeYellow;
        border: none;
      }

      &::after {
        background-color: $ghostWhite;
        border: 1px solid $alto;
      }

      &:hover {
        color: $black;
      }
    }
  }

  .icon {
    margin-right: 10px;
  }

  span {
    vertical-align: middle;
  }

  &:active, &:focus {
    outline: none;
  }
}
</style>
