import storiesService from '@/views/Products/ProductsService';

export default {
  applyFilters({ commit }, filters) {
    commit('applyFilters', filters);
  },

  async getProducts({ commit, state }, { append = true }) {
    if (state.fetching) return;
    commit('setFetching', { val: true });

    const { items: items_, total } = await storiesService.getProducts(state.filters);
    let items = [];
    if ((state.filters.page > 1) && append) {
      items = items.concat(state.items);
    }
    items = items.concat(items_);

    commit('setItems', { items, total });
    commit('setFetching', { val: false });
  },

  reset({ commit }) {
    commit('reset');
  }
};
