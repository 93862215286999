import HttpMethod from '@/consts/httpMethod';
import { processRequest, processErrorResponse, processSuccessResponse } from '@/helpers';

export default {
  async getStoryBySlug(slug) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: `/v1/stories/slug-${slug}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, {});
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getStoryPieceBySlug(slug) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: `/v1/story-pieces/slug-${slug}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, {});
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getStoryPieceById(id) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: `/v1/story-pieces/${id}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, {});
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async validatePurchaseCode(storyId, code) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: `/v1/stories/${storyId}:validate-purchase-code`,
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data: { code } });
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
