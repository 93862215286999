<template>
  <div id="paypal-payment">
    <div id="paypal-button-holder" ref="paypalButtonRef" />
  </div>
</template>

<script>
import { onMounted, ref, watchEffect, watch, computed } from 'vue';
import { loadScript } from '@paypal/paypal-js';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import orderService from '@/services/order';

export default {
  props: {
  },
  setup(props, { emit }) {
    const { locale } = useI18n();
    const store = useStore();

    const paypal = ref(null);
    const paypalButtonRef = ref(null);

    const rate = computed(() => store.state.app.usdToVndExchangeRate);
    const items = computed(() => store.state.cart.items);
    const totalAmount = computed(() => store.getters['cart/totalAmount']);

    const load = async () => {
      try {
        const res = await loadScript({
          'client-id': process.env.VUE_APP_PAYPAL_CLIENT_ID,
          locale: `${locale.value}_VN`,
        });
        paypal.value = res;
      } catch (error) {
        store.dispatch('flash/error', 'PAYPAL_ERROR');
      }
    };

    const renderButton = () => {
      paypal.value.Buttons({
        style: {
          height: 42
        },
        createOrder(data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: parseFloat(((totalAmount.value * 0.96) / rate.value).toFixed(2)),
                currency_code: 'USD'
              }
            }]
          });
        },
        async onApprove(data) {
          const { orderID: externalId } = data;
          const res = await orderService.createOrder({
            gateway: 'PAYPAL',
            externalId,
            items: items.value.map((item) => ({
              product: item.product.id,
              quantity: item.quantity
            })),
            locale: locale.value
          });

          emit('success', res);
        },
        onError() {
          store.dispatch('flash/error', 'ERROR_OCCURRED_PLEASE_TRY_AGAIN');
        }
      }).render(paypalButtonRef.value);
    };

    onMounted(() => {
      store.dispatch('app/getUsdToVndExchangeRate');
      load();
    });

    watchEffect(() => {
      if (paypal.value && paypalButtonRef.value) {
        renderButton();
      }
    });

    watch(locale, () => {
      load();
    });

    watch(props, () => {
      load();
    });

    return {
      paypalButtonRef
    };
  }
};
</script>
