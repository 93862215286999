import { DEFAULT_PROFILE_PICTURE } from '@/consts';

import { SET_TOKEN, SET_REFRESH_TOKEN, SET_PROFILE, CLEAR } from './types';

export const INIT_STATE = {
  token: '',
  refreshToken: '',
  profile: {
    username: '',
    firstName: '',
    lastName: '',
    picture: DEFAULT_PROFILE_PICTURE
  }
};

const mutations = {
  [SET_TOKEN](state, token = '') {
    state.token = token;
  },

  [SET_REFRESH_TOKEN](state, refreshToken = '') {
    state.refreshToken = refreshToken;
  },

  [SET_PROFILE](state, { firstName = '', lastName = '', picture = DEFAULT_PROFILE_PICTURE, username = '' }) {
    state.profile = { firstName, lastName, picture: picture || DEFAULT_PROFILE_PICTURE, username };
  },

  [CLEAR](state) {
    Object.keys(INIT_STATE).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(INIT_STATE, key)) {
        state[key] = INIT_STATE[key];
      }
    });
  }
};

export default mutations;
