import HttpMethod from '@/consts/httpMethod';
import { processRequest, processSuccessResponse, processErrorResponse } from '@/helpers';

export default {
  async login(username, password) {
    const ep = {
      baseURL: process.env.VUE_APP_AUTH_API_ROOT,
      url: '/v1/tokens',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data: { username, password } });
      const data = processSuccessResponse(res);

      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async refreshToken(refreshToken) {
    const ep = {
      baseURL: process.env.VUE_APP_AUTH_API_ROOT,
      url: '/v1/tokens/access-token',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data: { refreshToken } });
      const data = processSuccessResponse(res);

      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getProfile() {
    const ep = {
      baseURL: process.env.VUE_APP_AUTH_API_ROOT,
      url: '/v1/me',
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      const data = processSuccessResponse(res);

      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
