<template>
  <footer :class="{ dark }">
    <p>{{ t('POWERED_BY_XOI') }}</p>
  </footer>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default {
  name: 'Footer',
  components: {
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const dark = computed(() => route.meta.dark);

    return {
      t,
      dark
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

footer {
  text-align: center;
  color: $black;
  font-size: 12px;
  height: $footerHeight;
  box-sizing: border-box;
  padding-top: 20px;

  p {
    margin: 0;
  }

  &.dark {
    background-color: $black;
    color: $white;
  }
}
</style>
