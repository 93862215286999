import miscsService from '@/services/miscs';

const actions = {
  setSize({ commit }, w) {
    commit('setSize', w);
  },

  setLocale({ commit }, locale) {
    commit('setLocale', locale);
  },

  userSelectedLocale({ commit }) {
    commit('userSelectedLocale');
  },

  addPurchasedStory({ commit, state }, storyId) {
    const exists = !!state.purchasedStories.find((item) => item === storyId);
    if (!exists) {
      commit('addPurchasedStory', storyId);
    }
  },

  async getUsdToVndExchangeRate({ commit, state }) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (state.lastExchangeRateSetAt && (state.lastExchangeRateSetAt >= yesterday.getTime().toString())) return;

    const { rate } = await miscsService.getExchangeRate('USD', 'VND');
    commit('setUsdToVndExchangeRate', rate);
  }
};

export default actions;
