<template>
  <div id="service-fee">
    <particle-wave :particle-size="9" :number-of-particles="600" />
    <div class="content">
      <div class="wrapper">
        <h1>{{ t('SERVICE_FEE') }}</h1>

        <div v-show="(locale === 'en')">
          <p><strong>SOUND SERVICE FEE</strong></p>
          <p>Working hours: 8am - 11pm</p>
          <p>
            <table>
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Unit</th>
                  <th>Fee per unit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Studio recording as demanded<br/>(with a sound engineer)</td>
                  <td>Hour of work</td>
                  <td>500,000 ₫</td>
                </tr>
                <tr>
                  <td>Recording studio for rent<br/>(without a sound engineer)</td>
                  <td>Day of rent</td>
                  <td>1,200,000 ₫</td>
                </tr>
                <tr>
                  <td>Harmonize a song<br/>2 instruments</td>
                  <td>Minute of song</td>
                  <td>1,500,000 ₫</td>
                </tr>
                <tr>
                  <td>Harmonize a song<br/>3 instruments</td>
                  <td>Minute of song</td>
                  <td>3,000,000 ₫</td>
                </tr>
                <tr>
                  <td>Harmonize a song<br/>4 - 5 instruments</td>
                  <td>Minute of song</td>
                  <td>4,000,000 ₫</td>
                </tr>
                <tr>
                  <td>Harmonize a song<br/>more than 5 instruments</td>
                  <td>Hour of work</td>
                  <td>500,000 ₫</td>
                </tr>
                <tr>
                  <td>Studio musician for recording</td>
                  <td>Hour of work</td>
                  <td>500,000 ₫</td>
                </tr>
                <tr>
                  <td>Sound engineer for event</td>
                  <td>Hour of work</td>
                  <td>500,000 ₫</td>
                </tr>
                <tr>
                  <td>Recording technique consulting</td>
                  <td>Hour of work</td>
                  <td>500,000 ₫</td>
                </tr>
                <tr>
                  <td>Sound system consulting</td>
                  <td>Venue</td>
                  <td>2,000,000 ₫</td>
                </tr>
                <tr>
                  <td>Acoustic consulting</td>
                  <td>Venue</td>
                  <td>3,000,000 ₫</td>
                </tr>
              </tbody>
            </table>
          </p>
        </div>

        <div v-show="(locale === 'vi')">
          <p><strong>DỊCH VỤ THU ÂM</strong></p>
          <p>Thời gian hoạt động mỗi ngày từ 8h - 23h</p>
          <p>
            <table>
              <thead>
                <tr>
                  <th>Dịch vụ</th>
                  <th>Đơn vị tính</th>
                  <th>Đơn giá</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Thu âm hoàn thiện theo yêu cầu<br/>(có kĩ thuật viên trực máy)</td>
                  <td>Giờ làm việc</td>
                  <td>500.000 ₫</td>
                </tr>
                <tr>
                  <td>Thuê phòng thu<br/>(không có kĩ thuật viên trực máy)</td>
                  <td>Ngày thuê</td>
                  <td>1.200.000 ₫</td>
                </tr>
                <tr>
                  <td>Hòa âm<br/>2 nhạc cụ</td>
                  <td>Phút của bài</td>
                  <td>1.500.000 ₫</td>
                </tr>
                <tr>
                  <td>Hòa âm<br/>3 nhạc cụ</td>
                  <td>Phút của bài</td>
                  <td>3.000.000 ₫</td>
                </tr>
                <tr>
                  <td>Hòa âm<br/>4 - 5 nhạc cụ</td>
                  <td>Phút của bài</td>
                  <td>4.000.000 ₫</td>
                </tr>
                <tr>
                  <td>Hòa âm<br/>trên 5 nhạc cụ</td>
                  <td>Giờ làm việc</td>
                  <td>500.000 ₫</td>
                </tr>
                <tr>
                  <td>Nhạc công thu âm nhạc cụ</td>
                  <td>Giờ làm việc</td>
                  <td>500.000 ₫</td>
                </tr>
                <tr>
                  <td>Kĩ thuật âm thanh cho sự kiện</td>
                  <td>Giờ làm việc</td>
                  <td>500.000 ₫</td>
                </tr>
                <tr>
                  <td>Cố vấn kĩ thuật thu âm</td>
                  <td>Giờ làm việc</td>
                  <td>500.000 ₫</td>
                </tr>
                <tr>
                  <td>Cố vấn hệ thống âm thanh</td>
                  <td>Địa điểm</td>
                  <td>2.000.000 ₫</td>
                </tr>
                <tr>
                  <td>Cố vấn âm học</td>
                  <td>Địa điểm</td>
                  <td>3.000.000 ₫</td>
                </tr>
              </tbody>
            </table>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';

import ParticleWave from '@/components/ParticleWave';

export default {
  name: 'ServiceFee',
  components: {
    ParticleWave
  },
  setup() {
    const { t, locale } = useI18n();

    const title = t('SERVICE_FEE');

    const { meta } = useMeta({
      title,
      og: {
        title: `${title} | XOI`
      },
    });

    watch(locale, () => {
      meta.title = t('SERVICE_FEE');
    });

    return {
      t,
      locale
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#service-fee {
  width: 100%;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});
    z-index: -1;
  }

  h1 {
    margin-bottom: 30px;
  }

  table {
    width: 100%;

    thead {
      border-bottom: 1px solid $alto;
    }

    th, td {
      padding: 7px 10px;
    }
  }
}
</style>
