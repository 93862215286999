const mutations = {
  setSize(state, w) {
    const breakpoint = 768;
    const breakpointTablet = 1200;

    state.w = w;

    if (w < breakpoint) {
      state.isMobile = true;
      state.isTablet = false;
      state.isDesktop = false;
    } else if ((w >= breakpoint) && (w < breakpointTablet)) {
      state.isMobile = false;
      state.isTablet = true;
      state.isDesktop = false;
    } else if (w >= breakpointTablet) {
      state.isMobile = false;
      state.isTablet = false;
      state.isDesktop = true;
    }
  },

  setLocale(state, locale) {
    state.locale = locale;
  },

  userSelectedLocale(state) {
    state.userSelectedLocale = true;
  },

  setUsdToVndExchangeRate(state, rate) {
    state.usdToVndExchangeRate = rate;
    state.lastExchangeRateSetAt = (new Date()).getTime().toString();
  },

  addPurchasedStory(state, storyId) {
    const exists = !!state.purchasedStories.find((item) => item === storyId);
    if (!exists) {
      state.purchasedStories.push(storyId);
    }
  }
};

export default mutations;
