<template>
  <div id="product-info">
    <particle-wave :particle-size="9" :number-of-particles="600" />
    <div class="content">
      <div class="wrapper" v-if="!!product?.id">
        <div class="row">
          <div class="col col-12 col-md-6">
            <div class="gallery">
              <swiper-container
                :slides-per-view="1"
                :space-between="15"
                :centered-slides="true"

                @slidechange="onSlideChange"
                ref="galleryRef"
              >
                <swiper-slide v-for="img in product.gallery" :key="img.id"><img class="gallery-img" :src="img.url" :alt="product.name" /></swiper-slide>
              </swiper-container>

              <div class="gallery-control prev" @click="galleryPrev()"><font-awesome-icon :icon="faArrowLeft" /></div>
              <div class="gallery-control next" @click="galleryNext()"><font-awesome-icon :icon="faArrowRight" /></div>
            </div>

            <div class="sub-gallery">
              <img v-for="(img, i) in product.gallery" :key="img.id" class="gallery-img" :src="img.url" :alt="product.name" @click="setGalleryImg(i)" />
            </div>
          </div>

          <div class="col col-12 col-md-6">
            <h1>{{ product.name }}</h1>
            <div class="price" v-if="!isDesktop">{{ addCommas(product.price, locale === 'vi' ? '.' : ',') }} ₫</div>
            <div class="controls">
              <div class="price" v-if="isDesktop">{{ addCommas(product.price, locale === 'vi' ? '.' : ',') }} ₫</div>
              <quantity-input :min="1" :max="10" v-model:value="quantity" />
              <primary-button inverse :text="t('ADD_TO_CART')" @click="addToCart()" :icon="faCartPlus" />
            </div>
            <div v-html="product.content" />

            <div class="btns">
              <primary-button :text="t('BACK')" @click="goToShop()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watchEffect, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';

import PATHS from '@/consts/paths';
import { addCommas } from '@/helpers';

import ParticleWave from '@/components/ParticleWave';
import PrimaryButton from '@/components/PrimaryButton';
import QuantityInput from '@/components/QuantityInput';

import ProductInfoService from './ProductInfoService';

export default {
  name: 'ProductInfo',
  components: {
    ParticleWave,
    FontAwesomeIcon,
    PrimaryButton,
    QuantityInput
  },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const slug = computed(() => route.params.slug);
    const isLoggedIn = computed(() => store.getters['user/isLoggedIn']);
    const isDesktop = computed(() => store.state.app.isDesktop);

    const product = ref({});
    const galleryRef = ref(null);
    const activeGalleryImg = ref(0);
    const quantity = ref(1);

    const goToShop = () => {
      router.push(PATHS.SHOP);
    };

    const onSlideChange = (e) => {
      activeGalleryImg.value = e.detail[0].activeIndex;
    };

    const setGalleryImg = (i) => {
      galleryRef.value.swiper.slideTo(i);
    };

    const galleryPrev = () => {
      galleryRef.value.swiper.slidePrev();
    };

    const galleryNext = () => {
      galleryRef.value.swiper.slideNext();
    };

    const addToCart = () => {
      store.dispatch('cart/addItem', { product: product.value, quantity: quantity.value });
      store.dispatch('flash/setActs', [
        {
          text: 'GO_TO_CART',
          act: () => {
            router.push(PATHS.CART);
            store.dispatch('flash/setActiveStatus', false);
          }
        }
      ]);
      store.dispatch('flash/info', 'ITEM_ADDED_TO_CART');
    };

    watchEffect(async () => {
      if (!isLoggedIn.value) return;
      if (!slug.value) return;

      try {
        product.value = await ProductInfoService.getProductBySlug(slug.value);
      } catch(e) {
        goToShop();
      }
    }, {
      immediate: true
    });

    return {
      t,
      locale,
      product,
      onSlideChange,
      galleryRef,
      setGalleryImg,
      faArrowLeft,
      faArrowRight,
      galleryPrev,
      galleryNext,
      addCommas,
      quantity,
      addToCart,
      isDesktop,
      goToShop
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#product-info {
  width: 100%;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});
    z-index: -1;
  }

  .content {
    .wrapper {
      max-width: 1170px;

      @media (max-width: 991px) {
        padding: 15px;
      }
    }

    @media (max-width: 991px) {
      padding-top: 0;
    }
  }

  .gallery {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    user-select: none;

    @media (max-width: 767px) {
      width: calc(100% + 30px);
      margin-left: -15px;
    }

    .gallery-img {
      width: 100%;
      border-radius: 4px;
      border: 1px solid $alto;
    }

    .gallery-control {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $alto;
      text-align: center;
      z-index: 9;
      padding-top: 5px;
      cursor: pointer;
      transition: all .2s ease-in-out;
      transform-origin: center;
      opacity: .7;

      &.prev {
        left: 12px;
        right: auto;
      }

      &.next {
        right: 12px;
        left: auto;
      }

      &:hover {
        opacity: 1;
        transform: translateY(-50%) scale(1.1);
      }
    }
  }

  .sub-gallery {
    width: calc(100% + 15px);
    margin-left: -7.5px;
    user-select: none;

    @media (max-width: 767px) {
      display: none;
    }

    .gallery-img {
      width: calc(25% - 15px);
      margin: 7.5px;
      border-radius: 4px;
      cursor: pointer;
      transition: all .2s ease-in-out;
      transform-origin: center;
      border: 1px solid $alto;

      &:hover {
        transform: scale(1.12);
        opacity: .9;
      }
    }
  }

  h1 {
    margin-bottom: 30px;
  }

  .price {
    font-family: "Trump Gothic Pro", Nunito;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    border: 1px solid $alto;
    border-radius: 4px;
    padding: 7px 30px;
    display: inline-block;
  }

  .controls {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      margin-top: 15px;
    }

    .primary-button {
      text-transform: uppercase;
      vertical-align: middle;
    }

    .input {
      vertical-align: middle;
    }
  }

  .btns {
    margin-top: 40px;
    padding: 0;

    .primary-button {
      text-transform: uppercase;
    }
  }
}
</style>
