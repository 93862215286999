import HttpMethod from '@/consts/httpMethod';
import { processRequest, processErrorResponse, processSuccessResponse } from '@/helpers';

export default {
  async getProductBySlug(slug) {
    const ep = {
      baseURL: process.env.VUE_APP_SITE_API_ROOT,
      url: `/v1/products/slug-${slug}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, {});
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
