<template>
  <div id="flash-message" v-bind:class="type" v-if="show" ref="elem" @mouseover="() => setHovering(true)" @mouseout="() => setHovering(false)">
    <div class="message" v-html="$t(message)" />
    <div class="close" @click="() => hide()">
      <div class="line line-1" />
      <div class="line line-2" />
    </div>

    <div class="acts" v-show="!!acts.length">
      <primary-button v-for="act in acts" :key="act.text" :text="t(act.text)" @click="act.act" colored />
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { TweenMax } from 'gsap';
import { useI18n } from 'vue-i18n';

import PrimaryButton from '@/components/PrimaryButton';

export default {
  name: 'FlashMessage',
  components: {
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const show = ref(false);
    const elem = ref(null);

    const isActive = computed(() => store.state.flash.isActive);
    const message = computed(() => store.state.flash.message);
    const type = computed(() => store.state.flash.type);
    const acts = computed(() => store.state.flash.acts);

    const hide = () => {
      store.dispatch('flash/setActiveStatus', false);
    };

    const setHovering = (val) => {
      store.dispatch('flash/setHovering', val);
    };

    watch(isActive, (newVal) => {
      if (elem.value) {
        if (newVal) {
          show.value = newVal;
        }

        TweenMax.to(elem.value, .2, {
          opacity: +newVal,
          onComplete: () => {
            if (!newVal) {
              show.value = newVal;
            }
          }
        });
      } else {
        show.value = newVal;
      }
    }, {
      immediate: true
    });

    return {
      t,
      elem,
      show,
      message,
      type,
      hide,
      acts,
      setHovering
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors";

#flash-message {
  position: fixed;
  bottom: 15px;
  left: 12px;
  max-width: 400px;
  min-width: 200px;
  border-radius: 2px;
  min-height: 40px;
  background-color: $white;
  color: $black;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
  padding: 12px 30px;
  padding-right: 50px;
  z-index:9999;
  transition: all 0.2s;

  .acts {
    margin-top: 15px;

    .primary-button {
      text-transform: uppercase;
    }
  }

  .close {
    position: absolute;
    top: 13px;
    right: 15px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    opacity: .5;
    transition: all .2s ease-in-out;

    .line {
        height: 2px;
        width: 15px;
        background-color: $black;
        transform-origin: center;
        margin: 0 auto;
        transition: all .2s ease-in-out;
    }

    .line-1 {
        transform: translateY(9px) rotate(45deg);
    }

    .line-2 {
        transform: translateY(7.25px) rotate(135deg);
    }

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    width: calc(100% - 30px);
  }

  &.info {
    background-color: $black;
    color: $white;

    .close {
      .line {
        background-color: $white;
      }
    }
  }

  &.error {
    background-color: $orangeYellow;
    color: $black;

    .close {
      .line {
        background-color: $black;
      }
    }
  }
}
</style>
