<template>
  <div id="checkout">
    <particle-wave :particle-size="9" :number-of-particles="600" />
    <div class="content">
      <div class="wrapper">
        <h1>{{ t('CHECKOUT') }}</h1>

        <div class="cart-items">
          <div v-for="item in items" :key="item.product.id" class="item">
            <div class="name">
              <strong>{{ item.product.name }}</strong><br />
              {{ item.product.description }}
            </div>
            <div class="quantity">{{ item.quantity }}</div>
            <div class="amount">{{ addCommas(item.product.price * item.quantity) }} ₫</div>
          </div>
          <div class="item total">
            <div class="name">{{ t('TOTAL') }}</div>
            <div class="quantity">{{ itemsCount }}</div>
            <div class="amount">{{ addCommas(totalAmount) }} ₫</div>
          </div>
        </div>

        <div class="order-info" v-if="!order">
          <h3>{{ t('PAYMENT_INFO') }}</h3>

          <radio-input :text="t('COST_ON_DELIVERY')" v-model:ref-value="paymentMethod" :value="COD" />
          <div class="cod" v-show="paymentMethod === COD">
            <div class="name-input">
              <text-input
                :placeholder="t('FIRST_NAME')"
                v-model:value="firstName"
                required
                :label="t('ENTER_YOUR_NAME')"
                ref="firstNameInput"
              />
              <text-input
                :placeholder="t('LAST_NAME')"
                v-model:value="lastName"
                ref="lastNameInput"
              />
            </div>

            <text-input
              :placeholder="t('EMAIL')"
              v-model:value="email"
              type="email"
              required
              :label="t('ENTER_YOUR_EMAIL')"
              ref="emailInput"
            />

            <text-input
              :placeholder="t('PHONE_NUMBER')"
              v-model:value="phoneNumber"
              type="phone"
              required
              :label="t('ENTER_YOUR_PHONE_NUMBER')"
              ref="phoneNumberInput"
            />

            <textarea-input
              rows="3"
              :placeholder="t('ADDRESS')"
              v-model:value="address"
              :label="t('ENTER_YOUR_ADDRESS')"
              required
              ref="addressInput"
            />

            <textarea-input
              rows="3"
              :placeholder="t('NOTES')"
              v-model:value="notes"
              :label="t('COMMENTS_ABOUT_YOUR_ORDER')"
              ref="notesInput"
            />
          </div>

          <radio-input :text="t('ONLINE_PAYMENT')" v-model:ref-value="paymentMethod" :value="ONLINE" class="online-payment-radio" />
          <paypal-payment v-show="paymentMethod === ONLINE" @success="setOrder" />
        </div>

        <div class="order-info" v-if="order">
          <h3>{{ t('YOUR_ORDER_IS_CONFIRMED') }}</h3>
          <div class="info">
            <div>{{ t('ORDER_CODE') }}: {{ order.publicId }}</div>
            <div>{{ t('DATE') }}: {{ moment(order.createdAt).format('DD/MM/YYYY') }}</div>
            <br />
            <div>{{ t('NAME') }}: {{ order.firstName }} {{ order.lastName }}</div>
            <div>{{ t('PHONE_NUMBER') }}: {{ order.phoneNumber }}</div>
            <div>{{ t('EMAIL') }}: {{ order.email }}</div>
            <div>{{ t('ADDRESS') }}: {{ order.address }}</div>
            <div>{{ t('NOTES') }}: {{ order.notes }}</div>
            <br/>
            <div>{{ t('THANK_YOU_FOR_YOUR_PURCHASE') }}</div>
          </div>
        </div>

        <div class="controls">
          <router-link :to="PATHS.CART" v-if="!order">
            <primary-button inverse :text="t('BACK_TO_CART')" />
          </router-link>
          <router-link :to="PATHS.STORE" v-if="order">
            <primary-button inverse :text="t('DONE')" />
          </router-link>
          <primary-button v-if="(itemsCount > 0) && !order" v-show="paymentMethod === COD" colored inverse :text="t('CHECKOUT')" @click="submitOrder()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import { useStore } from 'vuex';
import moment from 'moment';
import { useRouter } from 'vue-router';

import { addCommas } from '@/helpers';
import PATHS from '@/consts/paths';

import orderService from '@/services/order';

import ParticleWave from '@/components/ParticleWave';
import PrimaryButton from '@/components/PrimaryButton';
import TextInput from '@/components/TextInput';
import TextareaInput from '@/components/TextareaInput';
import RadioInput from '@/components/RadioInput';
import PaypalPayment from '@/components/PaypalPayment';

const COD = 'COD';
const ONLINE = 'ONLINE';

export default {
  name: 'Checkout',
  components: {
    ParticleWave,
    PrimaryButton,
    TextInput,
    TextareaInput,
    RadioInput,
    PaypalPayment
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();

    const title = t('CHECKOUT');

    const { meta } = useMeta({
      title,
      og: {
        title
      },
    });

    const firstName = ref('');
    const lastName = ref('');
    const phoneNumber = ref('');
    const email = ref('');
    const address = ref('');
    const notes = ref('');

    const firstNameInput = ref(null);
    const lastNameInput = ref(null);
    const phoneNumberInput = ref(null);
    const emailInput = ref(null);
    const addressInput = ref(null);
    const notesInput = ref(null);

    const paymentMethod = ref(ONLINE);

    const order = ref(null);

    const items = ref(store.state.cart.items);
    const itemsCount = ref(store.getters['cart/itemsCount']);
    const totalAmount = ref(store.getters['cart/totalAmount']);

    const updateItem = (item) => {
      store.dispatch('cart/updateItem', item);
    };

    const setOrder = (data) => {
      order.value = data;
      store.dispatch('cart/clear');
    };

    const submitOrder = async () => {
      const inputs = [
        firstNameInput.value,
        lastNameInput.value,
        phoneNumberInput.value,
        emailInput.value,
        addressInput.value,
        notesInput.value,
      ];

      let valid = true;

      inputs.forEach((input) => {
        input.validateInput();
        valid = valid && input.isValid;
      });

      if (valid) {
        try {
          const res = await orderService.createOrder({
            items: items.value.map((item) => ({
              product: item.product.id,
              quantity: item.quantity
            })),
            firstName: firstName.value,
            lastName: lastName.value,
            phoneNumber: phoneNumber.value,
            email: email.value,
            address: address.value,
            notes: notes.value,
            locale: locale.value
          });

          setOrder(res);
        } catch (e) {
          store.dispatch('flash/error', 'ERROR_OCCURRED_PLEASE_TRY_AGAIN');
        }
      }
    };

    watch(locale, () => {
      meta.title = t('CHECKOUT');
    });

    onMounted(() => {
      if (itemsCount.value <= 0) {
        router.replace(PATHS.STORE);
      }
    });

    return {
      t,
      locale,
      items,
      addCommas,
      updateItem,
      itemsCount,
      totalAmount,
      PATHS,

      firstName,
      lastName,
      phoneNumber,
      email,
      address,
      firstNameInput,
      lastNameInput,
      phoneNumberInput,
      emailInput,
      addressInput,
      notes,
      notesInput,

      paymentMethod,
      COD,
      ONLINE,

      submitOrder,
      order,
      setOrder,
      moment
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#checkout {
  width: 100%;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});
    z-index: -1;
  }

  .cart-items {
    margin-top: 80px;

    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      border-bottom: 1px solid $alto;
      padding-bottom: 12px;

      .name {
        width: 60%;
      }

      .amount {
        font-weight: bold;
      }

      &:last-child {
        border: 0;
      }

      &.total {
        font-weight: bold;
      }
    }
  }

  .order-info {
    margin-top: 50px;

    h3 {
      margin-bottom: 20px;
    }

    .input {
      width: 100%;
      margin-bottom: 20px;
    }

    small {
      color: $grey;
    }

    .name-input {
      .input {
        vertical-align: top;

        &:first-child {
          width: 30%;
        }

        &:last-child {
          width: calc(70% - 15px);
          margin-left: 15px;
        }
      }
    }
  }

  .controls {
    margin-top: 50px;
    text-align: right;

    .primary-button {
      margin-left: 15px;
      text-transform: uppercase;
    }
  }
}
</style>
