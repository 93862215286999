<template>
  <div id="nav-menu">
    <div class="menu-icon" @click="toggleMenu()" :class="{ active, white }">
      <div class="line line-1" />
      <div class="line line-2" />
      <div class="line line-3" />
    </div>

    <div class="screen" :class="{ active }">
      <div class="wrapper">
        <div class="menu">
          <template v-for="item in menuItems" :key="item.text">
            <router-link :to="item.path || '/'">
              <primary-button :text="t(item.text)"  @click="toggleMenu()" />
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import PATHS from '@/consts/paths';

import PrimaryButton from '@/components/PrimaryButton';

const menuItems = [
  {
    text: 'ABOUT_XOI',
    path: PATHS.ABOUT
  },
  {
    text: 'SOFTWARE_DEVELOPMENT',
    path: PATHS.UNDER_CONSTRUCTION
  },
  {
    text: 'GRAPHICS',
    path: PATHS.UNDER_CONSTRUCTION
  },
  {
    text: 'MUSIC',
    path: PATHS.MUSIC
  },
  {
    text: 'STORIES',
    path: PATHS.STORIES
  },
  {
    text: 'SHOP',
    path: PATHS.SHOP
  },
  {
    text: 'CONTACTS',
    path: PATHS.CONTACTS
  },
];

export default {
  name: 'NavMenu',
  components: {
    PrimaryButton
  },
  props: {
    white: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n();

    const active = ref(false);

    const toggleMenu = () => {
      active.value = !active.value;
    };

    return {
      t,
      toggleMenu,
      active,
      menuItems
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

#nav-menu {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;

  .menu-icon {
    width: 36px;
    height: 30px;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    vertical-align: middle;
    background: none;
    border: 0;
    padding-top: 2px;

    .line {
      height: 3px;
      border-radius: 3px;
      width: 100%;
      background-color: $black;
      margin-top: 3px;
      margin-bottom: 4px;
      float: right;
      transition: all 0.2s ease-in-out;
    }

    &.white {
      .line {
        background-color: $white;
      }
    }

    &.active {
      .line {
        background-color: $black;

        &.line-1 {
          transform: translateY(10px) rotate(45deg);
        }

        &.line-2 {
          transform: rotate(-45deg);
        }

        &.line-3 {
          width: 1;
          opacity: 0;
        }
      }
    }

    &:focus, &:active {
      border: 0;
      outline: none;
    }
  }

  .screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: -1;
    background-color: $white;
    transition: all .2s ease-in-out;
    overflow: hidden;

    &.active {
      height: 100%;
    }

    .wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;

      .menu {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        .primary-button {
          display: block;
          margin: 20px auto;
          text-transform: uppercase;
          width: 300px;
        }
      }
    }
  }
}
</style>
