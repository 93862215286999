import mutations, { INIT_STATE } from './mutations';
import actions from './actions';

const state = { ...INIT_STATE };

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
