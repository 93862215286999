import { createApp } from 'vue';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import { createMetaManager } from 'vue-meta';
import VueGtag from 'vue-gtag-next';
import { register } from 'swiper/element/swiper-element';

import { initInterceptors } from './helpers/interceptor';

import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/scss/main.scss';
import 'swiper/swiper.css';

import store from './store';
import i18n from './translation';
import router from './router';
import App from './App.vue';

gsap.registerPlugin(ScrollToPlugin);
register();

const metaManager = createMetaManager();

const app = createApp(App);

app.use(VueGtag, {
  property: {
    id: process.env.VUE_APP_GTAG_ID
  }
});

app.use(store);
app.use(router);
app.use(i18n);
app.use(metaManager);
app.mount('#app');

initInterceptors();
