import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import loader from '@/components/Loader/store';
import flash from '@/components/FlashMessage/store';

import stories from '@/views/Stories/store';
import products from '@/views/Products/store';

import app from './app';
import user from './user';
import cart from './cart';

const STORE_KEY = process.env.VUE_APP_STORE_KEY;
const namespace = 'xoi';
const key = `${namespace}.${STORE_KEY}`;

const vuexPersistLocal = createPersistedState({
  key,
  storage: window.localStorage,
  paths: ['app', 'user', 'cart']
});

const vuexPersistSession = createPersistedState({
  key,
  storage: window.sessionStorage,
  paths: []
});

const plugins = [
  vuexPersistLocal,
  vuexPersistSession
];

const store = createStore({
  plugins,
  modules: {
    app,
    user,
    loader,
    flash,
    stories,
    products,
    cart
  }
});

export default store;
