const mutations = {
  addItem(state, { product, quantity }) {
    const items = [...state.items];

    const i = items.findIndex((item) => item.product.id === product.id);
    if (i >= 0) {
      items[i].quantity += quantity;
    } else {
      items.push({ product, quantity });
    }

    state.items = items;
  },

  updateItem(state, { product, quantity }) {
    let items = [...state.items];

    const i = items.findIndex((item) => item.product.id === product.id);
    if (i >= 0) {
      if (quantity > 0) {
        items[i].quantity = quantity;
      } else {
        items = [
          ...items.slice(0, i),
          ...items.slice(i + 1, items.length)
        ];
      }
    } else {
      if (quantity > 0) {
        items.push({ product, quantity });
      }
    }

    state.items = items;
  },

  clear(state) {
    state.items = [];
  }
};

export default mutations;
