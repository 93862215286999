<template>
  <header :class="{ music: isMusic, active: menuActive }">
    <router-link :to="PATHS.HOME">
      <logo />
    </router-link>
    <div class="right">
      <router-link :to="PATHS.CART">
        <div class="cart" v-show="cartItemsCount !== 0">
          <font-awesome-icon :icon="faCartShopping" />
          <div class="count">{{ cartItemsCount }}</div>
        </div>
      </router-link>
      <primary-button class="locale" @click="toggleLocale()" :text="locale" />
      <nav-menu v-show="!isDesktop || !isHome" :white="isMusic" ref="menuRef" />
    </div>
  </header>
</template>

<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCartShopping } from '@fortawesome/pro-duotone-svg-icons';

import PATHS from '@/consts/paths';

import Logo from '@/components/Logo';
import PrimaryButton from '@/components/PrimaryButton';

import NavMenu from './NavMenu';

export default {
  name: 'Header',
  components: {
    Logo,
    PrimaryButton,
    NavMenu,
    FontAwesomeIcon
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const route = useRoute();

    const menuRef = ref(null);

    const isDesktop = computed(() => store.state.app.isDesktop);
    const cartItemsCount = computed(() => {
      const count = store.getters['cart/itemsCount'];
      if (count > 9) {
        return '9+';
      }

      return count;
    });

    const toggleLocale = () => {
      if (locale.value === 'en') {
        store.dispatch('app/setLocale', 'vi');
      } else {
        store.dispatch('app/setLocale', 'en');
      }

      store.dispatch('app/userSelectedLocale');
    };

    const isHome = computed(() => route.path === PATHS.HOME);
    const isMusic = computed(() => route.path === PATHS.MUSIC);
    const menuActive = computed(() => menuRef.value?.active);

    return {
      t,
      locale,
      toggleLocale,
      isDesktop,
      isHome,
      isMusic,
      PATHS,
      menuRef,
      menuActive,
      cartItemsCount,
      faCartShopping
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: $white;
  width: 100%;
  box-sizing: border-box;
  height: $headerHeight;

  .logo {
    width: 45px;

    @media (max-width: 359px) {
      width: 100px;
    }
  }

  .right {
    margin-left: auto;

    .cart {
      display: inline-block;
      vertical-align: middle;
      width: 42px;
      height: 42px;
      position: relative;
      font-size: 24px;
      margin-top: 3px;
      margin-right: 15px;
      cursor: pointer;
      transition: all .2s ease-in-out;
      border-radius: 50%;

      svg {
        vertical-align: middle;
        padding-top: 4px;
      }

      .count {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $orangeYellow;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 9px;
        padding-top: 2px;
        font-weight: bold;
        transition: all .2s ease-in-out;
      }

      &:hover {
        background-color: $black;

        .count {
          color: $black;
        }
      }
    }

    .locale {
      margin-top: 3px;
      text-transform: uppercase;
      width: 42px;
      height: 42px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      vertical-align: middle;
    }

    #nav-menu {
      vertical-align: middle;
      margin-left: 15px;
    }
  }

  &.music {
    position: fixed;
    top: 25px;
    left: 20px;
    background: none;
    width: calc(100% - 40px);

    .logo {
      color: $white;
      transition: all .2s ease-in-out;
    }

    &.active {
      .logo {
        color: $black;
      }
    }

    .right {
      .cart {
        background-color: $white;

        &:hover {
          background-color: $black;
        }
      }
    }
  }
}
</style>
