import { createI18n } from 'vue-i18n';

import en from './en.json';
import vi from './vi.json';

const messages = {
  en: {
    ...en
  },
  vi: {
    ...vi
  }
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  messages
});

export default i18n;
