<template>
  <div class="slick-holder" ref="holder">
    <slot></slot>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import $ from 'jquery';

import 'slick-carousel/slick/slick.min';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default {
  name: 'Slick',
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const holder = ref(null);

    onMounted(() => {
      $(holder.value).slick(props.options);
    });

    const play = () => {
      $(holder.value).slick('slickPlay');
    };

    const pause = () => {
      $(holder.value).slick('slickPause');
    };

    return {
      holder,
      play,
      pause
    };
  }
};
</script>

<style lang="scss">
.slick-holder {
  width: 100%;
  height: 100%;
}
</style>
