const actions = {
  addItem({ commit }, { product, quantity }) {
    commit('addItem', { product, quantity });
  },

  updateItem({ commit }, { product, quantity }) {
    commit('updateItem', { product, quantity });
  },

  clear({ commit }) {
    commit('clear');
  }
};

export default actions;
