<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }} | XOI</template>
  </metainfo>

  <app-header />

  <transition name="fade">
    <router-view />
  </transition>

  <app-footer v-show="!isMusic" />

  <loader />
  <flash-message />
</template>

<script>
import { onMounted, onUnmounted, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useMeta, useActiveMeta } from 'vue-meta';
import { useRoute } from 'vue-router';
import $ from 'jquery';

import PATHS from '@/consts/paths';

import i18n from '@/translation';

import AppHeader from '@/components/Header';
import AppFooter from '@/components/Footer';
import Loader from '@/components/Loader';
import FlashMessage from '@/components/FlashMessage';

import coverImg from '@/assets/imgs/cover-img.jpg';

import MetaService from '@/services/meta';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Loader,
    FlashMessage
  },
  setup() {
    const title = 'XOI';
    const description = 'XOI is a creativity outlet focuses on story telling and software engineering';
    const { meta } = useMeta({
      charset: 'utf8',
      title,
      description,
      og: {
        title,
        description,
        image: [coverImg]
      },
      htmlAttrs: {
        lang: ['en', 'vi']
      },
    });

    const metadata = useActiveMeta();
    const store = useStore();
    const route = useRoute();

    const dark = computed(() => route.meta.dark);
    const isMusic = computed(() => route.path === PATHS.MUSIC);

    const locale = computed(() => store.state.app.locale);
    const token = computed(() => store.state.user.token);

    const watchScreenSize = () => {
      if (process.browser) {
        const w = window.innerWidth;
        store.dispatch('app/setSize', w);
      }
    };

    const login = async () => {
      try {
        await store.dispatch('user/guestLogin');
      } catch(e) {
        store.dispatch('flash/error', 'LOGIN_ERROR');
      }
    };

    watch(locale, (val) => {
      i18n.global.locale.value = val;
    }, {
      immediate: true
    });

    watch(dark, (val) => {
      if (val) {
        $('body').addClass('dark');
      } else {
        $('body').removeClass('dark');
      }
    });

    watch(route, (val) => {
      MetaService.getMeta(val.path).then((data) => {
        if (!data || !data.title) return;

        meta.title = data.title;
        meta.description = data.description;
        meta.og = {
          title: `${data.title} | XOI`,
          description: data.description,
          image: data.image,
          'image:width': data.imageWidth?.toString(),
          'image:height': data.imageHeight?.toString()
        };
      });
    });

    onMounted(() => {
      window.addEventListener('resize', watchScreenSize);
      watchScreenSize();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', watchScreenSize);
    });

    watch(token, (val) => {
      if (!val) {
        login();
      }
    }, {
      immediate: true
    });

    return {
      metadata,
      dark,
      isMusic
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/fonts';
@import "~@/assets/scss/colors";
@import '~@/assets/scss/dimens';

body {
  font-family: Nunito, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $black;
  margin: 0;
  overflow-x: hidden;
  padding-top: $headerHeight;

  &.dark {
    background-color: $black;
  }
}

h1 {
  font-family: "Trump Gothic Pro", Nunito;
  font-weight: 500;
  text-transform: uppercase;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all .2s ease-in-out;

  &:hover {
    color: $orangeYellow;
  }
}

.content {
  margin: 20px;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  padding: 50px 0;
  text-align: justify;
  position: relative;
  z-index: 1;

  .wrapper {
    background-color: rgba(255, 255, 255, .97);
    box-sizing: border-box;
    padding: 30px;
    max-width: 600px;
    margin: 0 auto;

    &.full {
      max-width: none;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.list {
  .item {
    margin-bottom: 15px;

    .box {
      position: relative;
      border: 1px solid $alto;
      border-radius: 4px;
      padding: 15px;
      padding-bottom: 18px;

      .img-holder {
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        margin-bottom: 7px;
        border-radius: 2px;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      a {
        color: inherit;
        text-decoration: none;
        transition: all .2s ease-in-out;

        &:hover {
          color: $orangeYellow;
        }
      }

      h3 {
        font-size: 24px;
        font-family: 'Trump Gothic Pro';
        font-weight: bold;
        cursor: pointer;
        margin-bottom: 14px;

        &.inactive {
          color: $alto;
        }
      }

      p {
        margin: 0;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 12px;
      }

      .date {
        color: $grey;
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
}
</style>
