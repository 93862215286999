<template>
  <div id="products">
    <particle-wave :particle-size="9" :number-of-particles="600" />
    <div class="content">
      <div class="wrapper">
        <h1>{{ t('SHOP') }}</h1>

        <div class="list" ref="listRef">
          <div class="row">
            <div
              class="col col-xs-12 col-md-6 col-lg-4"
              v-for="(col, i) in itemsGrid"
              :key="i"
            >
              <div
                v-for="item in itemsGrid[i]"
                :key="item.id"
                class="item"
              >
                <div class="box">
                  <router-link :to="applyParams(PATHS.PRODUCT_INFO, item.slug)">
                    <h3>{{ item.name }}</h3>
                  </router-link>
                  <div class="img-holder" v-if="!!item.image">
                    <router-link :to="applyParams(PATHS.PRODUCT_INFO, item.slug)">
                      <img :src="item.image.url" :alt="item.name" />
                    </router-link>
                  </div>
                  <p>{{ item.description }}</p>
                  <div class="price">{{ addCommas(item.price, locale === 'vi' ? '.' : ',') }} ₫</div>
                  <div class="controls">
                    <primary-button inverse :text="t('ADD_TO_CART')" @click="addToCart(item, quantity[item.id])" :icon="faCartPlus" />
                    <quantity-input :min="1" :max="10" v-model:value="quantity[item.id]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { faCartPlus } from '@fortawesome/pro-duotone-svg-icons';

import { addCommas, applyParams } from '@/helpers';
import PATHS from '@/consts/paths';

import ParticleWave from '@/components/ParticleWave';
import PrimaryButton from '@/components/PrimaryButton';
import QuantityInput from '@/components/QuantityInput';

export default {
  name: 'About',
  components: {
    ParticleWave,
    PrimaryButton,
    QuantityInput
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();

    const listRef = ref(null);
    const filters = computed(() => store.state.products.filters);
    const isLoggedIn = computed(() => store.getters['user/isLoggedIn']);

    const title = t('SHOP');
    const description = t('XOI_OFFICIAL_STORE');

    const { meta } = useMeta({
      title,
      description,
      og: {
        title: `${title} | XOI`,
        description
      },
    });

    const quantity = ref([]);

    watch(locale, () => {
      meta.title = t('STORE');
    });

    const calculateItemHeightScore = (item) => {
      let h = 0;
      if (item?.image) h += 300;
      if (item?.description) h += item.description.length;

      return h;
    };

    const items = computed(() => store.state.products.items);
    const itemsGrid = computed(() => {
      let n = 3;
      if (store.state.app.isTablet) {
        n = 2;
      } else if (store.state.app.isMobile) {
        n = 1;
      }

      const stacks = Array(n).fill().map(() => []);
      const stackHeights = Array(n).fill(0);

      for (let i = 0; i < items.value.length; i += 1) {
        const minStack = stackHeights.indexOf(Math.min(...stackHeights));
        stacks[minStack].push({ ...items.value[i] });
        stackHeights[minStack] += calculateItemHeightScore(items.value[i]);
      }

      return stacks;
    });

    const addToCart = (product, q = 1) => {
      store.dispatch('cart/addItem', { product, quantity: q });
      store.dispatch('flash/setActs', [
        {
          text: 'GO_TO_CART',
          act: () => {
            router.push(PATHS.CART);
            store.dispatch('flash/setActiveStatus', false);
          }
        }
      ]);
      store.dispatch('flash/info', 'ITEM_ADDED_TO_CART');
    };

    watch(items, (items_) => {
      const quantity_ = [...quantity.value];

      items_.forEach((item) => {
        if (!quantity_[item.id]) {
          quantity_[item.id] = 1;
        }
      });

      quantity.value = quantity_;
    });

    watch(filters, () => {
      store.dispatch('products/getProducts', { append: false });
    });

    watch(isLoggedIn, (val) => {
      if (!val) return;
      store.dispatch('products/getProducts', { append: false });
    }, {
      immediate: true
    });

    return {
      t,
      locale,
      itemsGrid,
      listRef,
      addToCart,
      faCartPlus,
      addCommas,
      quantity,
      applyParams,
      PATHS
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#products {
  width: 100%;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});
    z-index: -1;
  }

  .content {
    .wrapper {
      background: none;
      max-width: 1170px;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }

    .list {
      margin-top: 50px;

      .item {
        .box {
          background-color: $white;
        }

        .price {
          font-family: "Trump Gothic Pro", Nunito;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 24px;
          margin: 5px 0;
        }

        .controls {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;

          .primary-button {
            text-transform: uppercase;
            vertical-align: middle;
          }

          .input {
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
