<template>
  <div
    class="quantity-input input"
    :class="{
      disabled
    }"
  >
    <font-awesome-icon :icon="faMinus" @click="modify(-1)" />
    <input
      v-bind="$attrs"
      v-bind:type="type"
      v-bind:placeholder="placeholder"
      v-model="wrappedValue"
      v-on="inputListeners"
      ref="input"
    />
    <font-awesome-icon :icon="faPlus" @click="modify(1)" />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';

export default {
  name: 'QuantityInput',
  components: {
    FontAwesomeIcon
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 99
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  },
  setup(props, { emit, listeners }) {
    const isValid = ref(false);
    const cacheValue = ref(props.value);
    const input = ref(null);

    const wrappedValue = computed({
      get() { return cacheValue.value; },
      set(value) {
        cacheValue.value = value;
        emit('update:value', value);
      }
    });

    const formatInput = (val_) => {
      let val = val_;

      if (val === '') {
        // do nothing
      } else {
        val = parseInt(val, 10);

        if (Number.isNaN(val)) {
          val = 0;
        }

        if (val < props.min) val = props.min;
        if (val > props.max) val = props.max;
      }

      return val;
    };

    const inputListeners = {
      ...listeners,
      click() {
        input.value.focus();
        emit('click');
      },
      input(event) {
        const val = formatInput(event.target.value);
        cacheValue.value = val;
        emit('update:value', val);
      },
      blur() {
        let val = cacheValue.value;
        if (!Number.isInteger(val)) val = props.min;
        cacheValue.value = val;
        emit('update:value', val);
      }
    };

    const blur = () => {
      input.value.blur();
    };

    const modify = (val) => {
      wrappedValue.value = formatInput(wrappedValue.value + val);
    };

    watch(props, (newProps) => {
      cacheValue.value = newProps.value;
    });

    return {
      isValid,
      inputListeners,
      wrappedValue,
      blur,
      input,
      faPlus,
      faMinus,
      modify
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';
@import '~@/assets/scss/dimens';

.quantity-input {
  display: inline-block;
  position: relative;
  height: 48px;
  width: $quantityInputWidth;

  input {
    border: 0;
    border: 1px solid $alto;
    border-radius: 2px;
    padding: 4px;
    font-display: fallback;
    font-family: Nunito, Arial, Tahoma;
    font-size: 14px;
    background-color: $white;
    color: $black;
    width: 48px;
    height: 36px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 6px;
    margin-left: 7px;
    margin-right: 7px;

    &::placeholder {
      color: $alto;
    }

    &:active, &:focus {
      outline: none;
    }

    &::selection {
      background: none;
    }
  }

  svg {
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      color: $orangeYellow;
    }
  }

  &.disabled {
    pointer-events: none;
    color: $grey;

    input, label {
      color: $grey;
    }
  }
}
</style>
