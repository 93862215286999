import getters from './getters';
import actions from './actions';
import mutations, { INIT_STATE } from './mutations';

export default {
  namespaced: true,
  state: { ...INIT_STATE },
  getters,
  actions,
  mutations
};
