<template>
  <div id="upside-down-text">
    <particle-wave :particle-size="9" :number-of-particles="600" />
    <div class="content">
      <div class="wrapper">
        <h1>{{ t('UPSIDE_DOWN_TEXT_GENERATOR') }}</h1>

        <p>{{ t('UPSIDE_DOWN_TEXT_GENERATOR_INTRO') }}</p>

        <textarea-input
          rows="5"
          :placeholder="t('INPUT_TEXT')"
          v-model:value="text"
          :label="t('ENTER_YOUR_TEXT')"
          ref="textInput"
        />

        <textarea-input
          rows="5"
          :placeholder="t('CONVERTED_TEXT')"
          :value="convertedText"
          :label="t('CONVERTED_TEXT')"
          ref="textOutput"
          @focus="selectText"
        />

        <p v-show="locale === 'vi'">Chỉnh chữ ngược thì trên internet cũng có nhiều rồi. Nhưng cho tiếng Việt thì không thấy, vậy nên XOI quất code luôn.<br /> Xịn không? Vỗ tay đi.</p>
        <p v-show="locale !== 'vi'">There are many upside down text generators on the internet. But we can't find one for Vietnamese characters, so we code it ourselves.<br /> Clap if you like.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, onMounted, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMeta } from 'vue-meta';
import { useStore } from 'vuex';

import ParticleWave from '@/components/ParticleWave';
import TextareaInput from '@/components/TextareaInput';

import { upsideDownText } from '@/helpers';

export default {
  name: 'About',
  components: {
    ParticleWave,
    TextareaInput
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const text = ref('');
    const textOutput = ref(null);

    const convertedText = computed(() => {
      if (text.value.length > 1000) {
        return t('TEXT_TOO_LONG');
      }

      return upsideDownText(text.value);
    });

    const title = 'Oánh chữ ngược';
    const description = '¿ɓuo̬ɥ ɔǫ̇ӆɓu ɥɔᴉ̗ɥꞱ? Tạo chữ ngược của bạn tại đây.';

    const { meta } = useMeta({
      title,
      description,
      og: {
        title: `${title} | XOI`,
        description
      },
    });

    const selectText = (e) => {
      if (!textOutput.value) return;

      e.target.setSelectionRange(0, e.target.value.length);
    };

    onMounted(() => {
      if (!store.state.app.userSelectedLocale) {
        store.dispatch('app/setLocale', 'vi');
      }
    });

    watch(locale, () => {
      meta.title = t('UPSIDE_DOWN_TEXT_GENERATOR');
      meta.description = t('UPSIDE_DOWN_TEXT_GENERATOR_INTRO');
    });

    return {
      t,
      locale,

      text,
      convertedText,
      textOutput,
      selectText
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#upside-down-text {
  width: 100%;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});
    z-index: -1;
  }

  h1 {
    margin-bottom: 30px;
  }

  .input {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
