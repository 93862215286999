<template>
  <div id="story-piece">
    <particle-wave :particle-size="9" :number-of-particles="600" :r="255" :g="255" :b="255" />
    <div class="content">
      <template v-if="!list">
        <div class="wrapper">
          <div class="img-holder">
            <img v-if="piece.image" :alt="piece.title" :src="piece.image.url" />
          </div>

          <router-link v-if="story.pieces?.length > 1" :to="applyParams(PATHS.STORY_PIECE, story.slug)" >
            <h2>{{ story.title }}</h2>
          </router-link>
          <h1>{{ story.pieces?.length > 1 ? piece.title : story.title }}</h1>

          <div v-if="!requiresPurchase || purchased || purchaseSkipped || (story.price <= 0)" v-html="piece.content" />

          <div class="space" v-if="purchaseSkipped" />

          <div v-if="requiresPurchase && !purchased && (story.price > 0)" class="purchase">
            <div class="price">
              <div class="text">{{ t('WOW_PLEASE_PURCHASE_TO_CONTINUE_READING') }}</div>
              <div class="amount">{{ addCommas(story.price) }} ₫</div>
            </div>
            <paypal-payment-for-story :story="story" @success="setOrder" />

            <div class="btns" v-show="!haveRedeemCode">
              <p>{{ t('THE_SYSTEM_IS_STUPID') }}</p>
              <primary-button inverse :text="t('I_PAID_BEFORE')" @click="toggleUseRedeemCode" />
              <primary-button :text="t('LET_ME_READ_FIRST')" v-if="!purchaseSkipped" @click="skipPurchase" />
            </div>

            <div class="redeem" v-show="haveRedeemCode">
              <p>{{ t('USE_REDEEM_CODE_CTA') }}</p>

              <form @submit="checkRedeemCode">
                <text-input
                  :placeholder="t('USE_REDEEM_CODE')"
                  v-model:value="redeemCode"
                  required
                  :label="t('ENTER_YOUR_REDDEM_CODE')"
                />

                <div class="btns">
                  <primary-button inverse :text="t('USE_REDEEM_CODE')" type="submit" @click="checkRedeemCode" />
                  <primary-button :text="t('BACK')" @click="toggleUseRedeemCode" />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="btns-outer-wrapper">
          <div class="btns" :class="{ piece: !atBottom }">
            <div class="btns-wrapper">
              <primary-button :text="t('PREV_CHAPTER')" @click="goToPiece(piece.prev)" :class="{ hidden: !piece.prev }" v-show="piece.prev || !isMobile" />
              <primary-button :text="t('BACK')" @click="goBack" />
              <primary-button :text="t('NEXT_CHAPTER')" @click="goToPiece(piece.next)" :class="{ hidden: !piece.next }" v-show="piece.next || !isMobile" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="wrapper list">
          <div class="img-holder">
            <img v-if="story.coverImage" :alt="story.title" :src="story.coverImage.url" />
          </div>
          <h1>{{ story.title }}</h1>
          <p>{{ story.description }}</p>

          <div v-for="item in pieces" :key="item.id" class="piece-item">
            <router-link :to="applyParams(PATHS.STORY_PIECE, `${story.slug}/${item.slug}`)" >
              <div class="img-holder">
                <img v-if="item.image" :alt="item.title" :src="item.image.url" />
              </div>
            </router-link>
            <div class="text">
              <router-link :to="applyParams(PATHS.STORY_PIECE, `${story.slug}/${item.slug}`)" >
                <h3>{{ item.title }}</h3>
              </router-link>
              <div class="date">{{ moment(item.createdAt).format(`${locale === 'en' ? 'DD MMM, YYYY' : 'DD [tháng] MM, YYYY'}`) }}</div>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>

        <div class="btns">
          <primary-button :text="t('BACK')" @click="goToStories" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { watchEffect, computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';

import ParticleWave from '@/components/ParticleWave';
import PrimaryButton from '@/components/PrimaryButton';
import TextInput from '@/components/TextInput';
import PaypalPaymentForStory from '@/components/PaypalPaymentForStory';

import PATHS from '@/consts/paths';
import { applyParams, scrollToTop, addCommas } from '@/helpers';

import StoryPieceService from './StoryPieceService';

export default {
  name: 'StoryPiece',
  components: {
    ParticleWave,
    PrimaryButton,
    PaypalPaymentForStory,
    TextInput
  },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const slug = computed(() => route.params.slug);
    const isLoggedIn = computed(() => store.getters['user/isLoggedIn']);
    const story = ref({});
    const piece = ref({});
    const pieces = ref([]);
    const list = ref(false);

    const atBottom = ref(false);
    const isMobile = computed(() => store.state.app.isMobile);

    const purchaseSkipped = ref(false);
    const haveRedeemCode = ref(false);
    const redeemCode = ref('');
    const requiresPurchase = computed(() => ((story.value.requiresPurchaseAtIndex >= 0) && (story.value.requiresPurchaseAtIndex <= piece.value.order)));
    const purchased = computed(() => !!store.state.app.purchasedStories.find((item) => item === story.value.id));

    const goToStories = () => {
      router.push(PATHS.STORIES);
    };

    const goToList = () => {
      router.push(applyParams(PATHS.STORY_PIECE, story.value.slug));
    };

    const goToPiece = (slug_) => {
      router.push(applyParams(PATHS.STORY_PIECE, `${story.value.slug}/${slug_}`));
    };

    const goBack = () => {
      if (story.value.pieces?.length > 1) {
        goToList();
      } else {
        goToStories();
      }
    };

    const watchScrollY = () => {
      if (process.browser) {
        const y = window.scrollY;
        const h = window.document.body.scrollHeight;
        const screenH = window.innerHeight;
        const delta = 170;

        atBottom.value = (Math.round(y + screenH + delta) >= h);
      }
    };

    const preventContextMenu = (e) => {
      e.preventDefault();
    };

    const setOrder = () => {
      store.dispatch('app/addPurchasedStory', story.value.id);
      store.dispatch('flash/error', 'THANK_YOU_FOR_YOUR_PURCHASE');
    };

    const skipPurchase = () => {
      purchaseSkipped.value = true;
    };

    const toggleUseRedeemCode = () => {
      haveRedeemCode.value = !haveRedeemCode.value;
    };

    const checkRedeemCode = async (e, preventAlert) => {
      if (e) {
        e.preventDefault();
      }

      try {
        await StoryPieceService.validatePurchaseCode(story.value.id, redeemCode.value);
        store.dispatch('app/addPurchasedStory', story.value.id);

        if (!preventAlert) {
          store.dispatch('flash/error', 'THANK_YOU_FOR_YOUR_PURCHASE');
        }

        redeemCode.value = '';
      } catch (err) {
        if (!preventAlert) {
          store.dispatch('flash/error', 'INVALID_CODE');
        }
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', watchScrollY);
      window.addEventListener('contextmenu', preventContextMenu);

      watchScrollY();
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', watchScrollY);
      window.removeEventListener('contextmenu', preventContextMenu);
    });

    watchEffect(async () => {
      if (!isLoggedIn.value) return;
      if (!slug.value) return;

      try {
        const parts = slug.value.split('/');
        story.value = await StoryPieceService.getStoryBySlug(parts[0]);

        const { code } = route.query;
        if (code) {
          redeemCode.value = code;
          checkRedeemCode(null, true);
          router.replace({ query: {} });
        }

        if (parts.length > 1) {
          piece.value = await StoryPieceService.getStoryPieceBySlug(parts[1]);
          list.value = false;
        } else {
          if (story.value.pieces.length === 1) {
            piece.value = await StoryPieceService.getStoryPieceById(story.value.pieces[0].id);
            list.value = false;
          } else if (story.value.pieces.length > 1) {
            pieces.value = story.value.pieces;
            list.value = true;
          }
        }
      } catch(e) {
        goToStories();
      }
    }, {
      immediate: true
    });

    watch(requiresPurchase, (r) => {
      if (r && !purchased.value) {
        // TODO: show purchase popup
      }
    });

    watch(slug, () => {
      scrollToTop();
      purchaseSkipped.value = false;
    });

    watch(story, (val) => {
      if (!store.state.app.userSelectedLocale) {
        store.dispatch('app/setLocale', val.lang);
      }
    });

    return {
      t,
      locale,
      list,
      piece,
      pieces,
      story,
      goToStories,
      PATHS,
      applyParams,
      goToList,
      goBack,
      moment,
      goToPiece,
      isMobile,
      atBottom,
      requiresPurchase,
      purchased,
      addCommas,
      purchaseSkipped,
      setOrder,
      skipPurchase,
      toggleUseRedeemCode,
      haveRedeemCode,
      redeemCode,
      checkRedeemCode
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import "~@/assets/scss/dimens";

#story-piece {
  width: 100%;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  position: relative;
  overflow: hidden;
  background-color: $black;

  #particle-wave {
    position: fixed;
    top: $headerHeight;
    left: 0;
    width: 100%;
    height: calc(100vh - #{$footerHeight} - #{$headerHeight});

    canvas {
      background-color: $black;
    }
  }

  .content {
    .wrapper {
      background-color: $white;
      border-radius: 4px;
      user-select: none;

      @media only print {
        color: $white;
      }

      .img-holder {
        margin-bottom: 20px;

        img {
          width: 100%;
          border-radius: 4px;
        }
      }

      h2 {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      &.list {
        h1 {
          text-transform: uppercase;
          font-size: 40px;
          font-weight: bold;
          margin-bottom: 5px;
          font-family: Nunito;
        }
      }
    }

    .btns-outer-wrapper {
      min-height: 110px;
    }

    .btns {
      padding: 30px 0;
      text-align: center;

      @media (max-width: 767px) {
        padding: 15px 0;
      }

      .btns-wrapper {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
          &.hidden {
            opacity: 0;
            pointer-events: none;
          }
        }

        @media (min-width: 768px) {
          max-width: 600px;
        }
      }

      &.piece {
        padding: 15px 30px;
        position: fixed;
        z-index: 999;
        width: 100%;
        bottom: 0;
        left: 0;

        @media (max-width: 767px) {
          padding: 15px;
        }

        .btns-wrapper {
          max-width: none;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $black;
          opacity: .9;
        }
      }
    }
  }

  .piece-item {
    margin: 20px 0;
    border-bottom: 1px solid $alto;

    &:last-child {
      border: 0;
    }

    .img-holder {
      width: 110px;
      height: 110px;
      margin-right: 12px;
      background-color: $grey;
      display: inline-block;
      border-radius: 4px;
      vertical-align: top;

      @media (max-width: 767px) {
        width: 90px;
        height: 90px;
        margin-right: 7px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      width: calc(100% - 110px - 12px);
      display: inline-block;
      vertical-align: top;

      @media (max-width: 767px) {
        width: calc(100% - 90px - 7px);
      }

      h3 {
        margin-bottom: 4px;
        font-family: "Trump Gothic Pro", Nunito;
        font-size: 24px;
      }

      .date {
        color: $grey;
        font-size: 12px;
      }
    }
  }

  .space {
    padding-top: 30px;
    margin-top: 50px;
    border-top: 1px solid $alto;
  }

  .purchase {
    .price {
      margin-bottom: 15px;

      .text {
        display: inline-block;
        width: 70%;
        vertical-align: middle;
      }

      .amount {
        display: inline-block;
        width: 30%;
        font-family: "Trump Gothic Pro", Nunito;
        font-weight: bold;
        font-size: 48px;
        text-align: right;
        vertical-align: middle;
      }
    }

    .redeem {
      margin-top: 30px;
      text-align: center;

      .input {
        width: 100%;
        margin-bottom: 10px;
        text-align: left;
      }

      .btns {
        margin-top: 0;
      }
    }

    .btns {
      margin-top: 40px;
      padding: 0;

      .primary-button {
        margin-bottom: 10px;
        width: 100%;
        text-transform: uppercase;
      }
    }
  }
}
</style>
