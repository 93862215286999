import jwtDecode from 'jwt-decode';

const getters = {
  isLoggedIn: (state) => !!state.refreshToken,
  permissions: (state) => {
    if (!state.token) return [];
    const payload = jwtDecode(state.token);
    return payload.permissions || [];
  }
};

export default getters;
